import React from "react";
import "./commitments.scss"

export default function Commitments(){
    return(
        <div id="commitments">
            <h2>NOS QUATRE ENGAGEMENTS</h2>

            <div className="commitmentContainer">
                <div className="commitment">
                    <img src={require("../images/labels/rating.png")} alt="" />
                    <p>Satisfaire nos clients à tout prix</p>
                </div>

                <div className="commitment">
                    <img src={require("../images/labels/animal-care.png")} alt=""  />
                    <p>Rendre vos animaux heureux</p>
                </div>

                <div className="commitment">
                    <img src={require("../images/labels/support.png")} alt="" />
                    <p>Une disponibilité sans faille</p>
                </div>

                <div className="commitment">
                    <img src={require("../images/labels/verification.png")} alt="" />
                    <p>Un contrôle qualité de tous nos produits</p>
                </div>
            </div>

        </div>
    )
}