import React, { useEffect, useState } from "react";
import { BuyingType, Order } from "../utils/generalTypes";
import "../pages/paymentPage.scss"
import "./adminOrders.scss"
import Summary from "./productSummary";
import { displayAlertModal } from "../utils/generalMethods";

export default function AdminOrders(){

    const [orders, setOrders] = useState<Order[]>([])
    const [searchDate, setSearchDate] = useState("")

    useEffect(()=>{
        const fetchOrders = async ()=>{
            const fetchOrdersReq = await (await fetch("/admin/all-orders")).text()
            if(fetchOrdersReq.includes("{")){
                const ordersObj:Order[] = JSON.parse(fetchOrdersReq)
                setOrders(ordersObj)
            }
            else{
                displayAlertModal(fetchOrdersReq)
            }
        }
        fetchOrders()
    }, [])


    let filteredOrders:Order[] = [...orders]
    searchDate !== "" ? filteredOrders = filteredOrders.filter(order=>order.date.includes(searchDate)) : filteredOrders.length = 30
    
    

    return(
        <div className="apOrders">
            <h2>Commandes</h2>
            <p>
                Par défaut, seules les 30 dernières commandes sont affichées. Si vous souhaitez retrouver une commande antérieure, veuillez indiquer ci-dessous sa date.<br/>
                Vous pouvez indiquer la date en format JJ, MM, YYYY, ou bien JJ/MM, MM/YYYY ou bien en format complet JJ/MM/YYYY.
            </p>
            <input id="search" name="search" type="text" placeholder="Date" value={searchDate} onChange={(e)=>{setSearchDate(e.target.value)}} />
            {
                filteredOrders.length ? 
                    filteredOrders.map((order, index)=>{
                        return(
                            <div key={index} className="apOrderItem">
                                <div className="apOrderTitle">
                                    <h4>{order.date} - {order.paymentInformations?.name ?? "Inconnu"} - {order.orderType === BuyingType.simpleBuy ? "Achat simple" : "Abonnement"} - {order.paymentInformations?.relayId ? "Livraison relais " + order.paymentInformations.relayId : "Livraison à domicile"}</h4>
                                    <h4>{order.totalPrice} €</h4>
                                </div>
                                <Summary productList={order.products} />
                            </div>
                        )
                    })
                : ""
            }
        </div>
    )
}