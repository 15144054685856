import React, { ReactElement, useState } from "react";
import "./adminPanel.scss"
import { AdminPageType } from "../utils/generalTypes";
import AdminHome from "../components/adminHome";
import AdminOrders from "../components/adminOrders";
import AdminProducts from "../components/adminProducts";
import AdminUsers from "../components/adminUsers";
import AdminPromos from "../components/adminPromos";

interface IMenuItem{
    title:string;
    imgSrc:string;
    value:AdminPageType;
}

export default function AdminPanel(){

    const [pageType, setPageType] = useState<AdminPageType>(AdminPageType.Home)

    const menuItemsList:IMenuItem[] = [
        {
            title:"Accueil",
            imgSrc:"home.png",
            value:AdminPageType.Home
        },
        {
            title:"Commandes",
            imgSrc:"orders.png",
            value:AdminPageType.Orders
        },
        {
            title:"Produits",
            imgSrc:"products.png",
            value:AdminPageType.Products
        },
        {
            title:"Utilisateurs",
            imgSrc:"users.png",
            value:AdminPageType.Users
        },
        {
            title:"Promos",
            imgSrc:"promos.png",
            value:AdminPageType.Promos
        }
    ]

    let pageToDisplay:ReactElement;
    switch(pageType){
        case AdminPageType.Orders:
            pageToDisplay = <AdminOrders/>
            break;
        case AdminPageType.Products:
            pageToDisplay = <AdminProducts/>
            break;
        case AdminPageType.Users:
            pageToDisplay = <AdminUsers/>
            break;
        case AdminPageType.Promos:
            pageToDisplay = <AdminPromos/>
            break;
        default:
            pageToDisplay = <AdminHome/>
            break;
    }

    return(
        <div id="adminPanel">
            <div className="apMenu">
                {
                    menuItemsList.map((menuItem:IMenuItem, index:number)=>{
                        return(
                            <div className="apMenuItem" key={index} onClick={()=>{setPageType(menuItem.value)}} >
                                <img src={require(`../images/admin/${menuItem.imgSrc}`)} alt=""/>
                                <p>{menuItem.title}</p>
                            </div>
                        )
                    })
                }
            </div>
            <div className="apContent">
                {pageToDisplay}
            </div>
        </div>
    )
}
