import React, { useEffect } from "react";
import "./paymentDonePage.scss"
import { useNavigate } from "react-router-dom";

export default function PaymentDonePage(){

    const navigate = useNavigate()

    useEffect(()=>{
        setTimeout(()=>{
            navigate("/")
        }, 2000)
    }, [])

    return(
        <div className="paymentDonePage">
            <p>Votre paiement a été confirmé, vous serez redirigé vers l'accueil dans un instant.</p>
            <div className="spinner"></div>
        </div>
    )
}