
import React, { useState } from "react"
import {useAppSelector } from "../reduxFolder/store"

import { displayAlertModal, setGeneralDataLocal } from "../utils/generalMethods"

export default function AdminHome(){

    const generalState = useAppSelector(state=>state.general.generalWebsiteData)


    const [bannerMessage, setBannerMessage] = useState<string>(generalState.bannerMessage)
    const [deliveryFee, setDeliveryFee] = useState<number>(generalState.deliveryFee)

    const changeWebsiteGeneralData = async (e:React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{
        e.preventDefault()
        const changeDataReq = await (
            await fetch("/admin/modify-general-data", {
                method:"POST",
                headers:{
                    "Content-Type":"application/json"
                },
                body:JSON.stringify({
                    bannerMessage,
                    deliveryFee
                })
            })
        ).text()
        displayAlertModal(changeDataReq)
        setGeneralDataLocal({bannerMessage, deliveryFee})
    }

    return(
        <div className="apHome">
            <h2>Accueil</h2>
            <p>
                Bienvenue sur l'accueil du panel administrateur de Rue Canine.<br/>
                Vous pouvez retrouver dans le menu à gauche plusieurs pages qui permettent d'effectuer des actions ou de visualiser différentes choses.
            </p>

            <h3>Informations générales du site</h3>

            <form>
                <input name="generalmessage" type="text" placeholder="Message de bienvenue" value={bannerMessage} onChange={(e)=>{setBannerMessage(e.target.value)}}/>
                <input name="delivery" type="number" placeholder="Frais de livraison" value={deliveryFee} onChange={(e)=>{setDeliveryFee(parseInt(e.target.value, 10))}}/>
                <button className="send" onClick={(e)=>{changeWebsiteGeneralData(e)}}>Modifier</button>
            </form>
        </div>
    )
}