import React from "react";
import "./splashScreen.scss"

export default function SplashScreen(){
    return(
        <div id="SplashScreen">
            <img src={require("../images/logo.png")} alt="Rue Canine"/>
            <h1>Bienvenue sur Rue Canine</h1>
            <p>Veuillez patienter, vous serez redirigé dans un instant</p>

            <div className="spinner"></div>
        </div>
    )
}