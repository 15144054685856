import React from "react"
import "./alertModal.scss"
import {useAppSelector } from "../reduxFolder/store"
import { confirmCallbackFunction, hideAlertModal } from "../utils/generalMethods"

export default function AlertModal(){

    const alertText = useAppSelector(state=>state.general.alertModalText)
    const alertDisplay = useAppSelector(state=>state.general.alertModalDisplay)
    const alertType = useAppSelector(state=>state.general.alertModalType)

    const closeModal = (confirmed?:boolean)=>{
        hideAlertModal()
        if(alertType === "yesno" && confirmed){
            confirmCallbackFunction()
        }
    }

    return(
        <div className="alertModalBackground" style={{display: alertDisplay ? "block" : "none"}} onClick={()=>{closeModal(false)}}>
            <div className="alertModal" onClick={(e)=>{e.stopPropagation()}}>
                <p>{alertText}</p>

                {
                    alertType === "simple" ? <button className="send" onClick={()=>{closeModal(false)}}>Fermer</button> :
                    <button className="send" onClick={()=>{closeModal(true)}}>Confirmer</button>
                }
                
            </div>
        </div>
    )
}