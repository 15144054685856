import React from "react";
import "./presentationPage.scss"

export default function CGVPage(){
    return(
        <div className="otherTextPage">
            <h2>Mentions légales et conditions générales de vente</h2>
            <p>
<br/>            <span className="bold">Article 1 – MENTIONS LÉGALES</span>
<br/>
<br/>            Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004 pour la confiance en l'économie numérique, il est précisé aux utilisateurs du site Rue Canine l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi.
<br/>
<br/>            Le présent site, accessible à l’URL (“ruecanine.fr”), est édité par :
<br/>
<br/>            Rue Canine auto-entreprise inscrite au R.C.S. de Paris sous le numéro 832 465 033, dont le siège social est situé à 95570 Bouffémont.
<br/>            (Ci-après désigné l’« Exploitant »).
<br/>
<br/>            Le Directeur de la publication du Site est CEM SIMSEK.
<br/>
<br/>            Le numéro individuel TVA de l’Exploitant est : FR 34 832 465 033
<br/>            L’Exploitant peut être joint à l’adresse mail suivante : contact@ruecanine.fr ou bien au numéro de téléphone suivant : 06 56 76 05 41.
<br/>
<br/>            Le Site est hébergé par la société 1&1 / IONOS, situé 7 Place de la Gare - 57200 Sarreguemines, (contact téléphonique ou email : (+33) 9 70 80 89 11).
<br/>
<br/>            L’administrateur du site, Gorkem GUL, peut être contacté par email à l’adresse gorkemguldev@gmail.com.
<br/>
<br/>            Le traitement de vos données à caractère personnel est régi par notre Charte du respect de la vie privée, disponible depuis la section "Vie privée", conformément au Règlement Général sur la Protection des Données 2016/679 du 27 avril 2016 («RGPD»).
<br/>
<br/>
<br/>            <span className="bold">Article 2 – DISPOSITIONS GÉNÉRALES RELATIVES AUX PRÉSENTES CONDITIONS GÉNÉRALES</span>
<br/>
<br/>            Les conditions générales de Vente (les « Conditions Générales de Vente », ou les « CGV ») sont applicables exclusivement à la vente en ligne des produits proposés par l’Exploitant sur le Site internet.
<br/>            Les CGV sont mises à la disposition des clients sur le Site où elles sont directement consultables et peuvent également lui être communiquées sur simple demande par tout moyen.
<br/>            Les CGV sont opposables au client qui reconnaît, en finalisant un quelconque achat sur le site, en avoir eu connaissance et les avoir acceptées avant de passer commande. La validation de la commande par sa confirmation vaut adhésion par l'acheteur aux CGV en vigueur au jour de la commande dont la conservation et la reproduction sont assurées par l’Exploitant.
<br/>            Rue Canine conserve la possibilité de modifier à tout moment ces conditions de ventes, afin de respecter toute nouvelle réglementation ou dans le but d’améliorer l’utilisation de son site.
<br/>
<br/>
<br/>            <span className="bold">Article 3 – Produits </span>
<br/>
<br/>            Les produits proposés sont ceux qui figurent sur le site de ruecanine.fr 
<br/>            Le Site est un site de vente en ligne de nourriture et d’accessoires pour animaux. 
<br/>            Les produits présents sur le site sont présentés sous forme de descriptif complet mentionnant leurs caractéristiques essentielles. Les photographies illustrant, le cas échéant, les produits ne constituent pas un document contractuel. Les produits sont conformes aux prescriptions du droit français. 
<br/>            Le Client reconnaît avoir vérifié que la configuration informatique qu’il utilise est sécurisée et en état de fonctionnement.
<br/>
<br/>
<br/>            <span className="bold">Article 4 – Tarifs</span>
<br/>
<br/>            Les prix figurant sur le site internet sont des prix en Euros (€). L’Exploitant étant au statut d’auto-entreprise, les prix sont en (HT) "TVA non applicable, article 293B du CGI". Tout changement du statut de notre entreprise pourra conduire à une application de la TVA qui pourrait se répercuter sur les prix des produits, qui s’afficheront désormais en TTC.
<br/>            Rue Canine se réserve le droit de modifier ses prix à tout moment.
<br/>            Les prix indiqués ne comprennent pas les frais de livraison, facturés ou non selon plusieurs conditions parmi lesquelles la livraison ou non à domicile par l’Exploitant en personne selon la distance géographique du client, le choix ou non du client de passer par un service tiers de livraison en point relais - à la date actuelle MondialRelay - ainsi que la composition de sa commande nécessitant ou non de faire un appel à un service de livraison par point relais.
<br/>            Dans l’hypothèse où des frais de livraison seraient appliqués, le client se verrait tenu au courant sur la page de paiement avec un prix total modifié ainsi qu’une indication claire du frais total de livraison intégré au prix total.
<br/>
<br/>
<br/>            <span className="bold">Article 5 – CRÉATION DE L’ESPACE CLIENT</span>
<br/>
<br/>            Le Client a la possibilité de créer son espace client personnel, et en a l’obligation lorsqu’il souhaite effectuer un achat sur le site.
<br/>
<br/>            Une fois créé, pour y accéder, le Client doit s’identifier en utilisant son identifiant - ici l’adresse e-mail utilisée lors de  la création du compte - et son mot de passe secret, personnel et confidentiel. 
<br/>
<br/>            Il appartient au Client de ne pas communiquer son identifiant et son mot de passe conformément aux dispositions de l’Article 16 « Données Personnelles » des présentes Conditions Générales. 
<br/>
<br/>            Chaque Client s'engage à conserver une stricte confidentialité sur les données, en particulier identifiant et mot de passe, lui permettant d'accéder à son espace client, le Client reconnaissant être le seul responsable de l'accès au Service par le biais de son identifiant et de son mot de passe, sauf fraude avérée et prouvée par des moyens concrets, par exemple un document prouvant un dépôt de plainte auprès de la police en cas d’usage frauduleux de ses coordonnées bancaires lors d’un achat effectué sur le site. 
<br/>
<br/>            Chaque Client s'engage en outre à informer sans délai l’Exploitant dans l'hypothèse d'une perte, d'un détournement ou de l'utilisation frauduleuse de son identifiant et/ou mot de passe.
<br/>
<br/>            Chaque Client s'engage également à avoir uniquement un seul compte client par foyer, dans le cas contraire il pourra se voir supprimer ses nouveaux comptes. Nous nous réservons le droit de rendre ces Clients inéligibles aux prochaines offres.
<br/>
<br/>            Après la création de son espace client personnel, le Client pourra éventuellement recevoir un email lui confirmant la création de son espace client, en plus de la confirmation affichée sur le site après la création effective du compte. 
<br/>
<br/>            Le Client s'engage lors de son inscription à :
<br/>
<br/>            • délivrer des informations réelles, exactes, à jour au moment de leur saisie dans le formulaire d'inscription du service, et notamment à ne pas utiliser de faux noms ou adresses, ou encore des noms ou adresses sans y être autorisé. De plus, les informations fournies par le client doivent être conformes aux formats attendues par le site, lesquels sont indiqués dans les champs d’entrée d’informations.
<br/>
<br/>
<br/>            L’Exploitant nie toute responsabilité lors d’un défaut de livraison par point relais par un service tiers dans le cas où les informations fournies par le client seraient erronées et auraient causé une quelconque incompréhension. 
<br/>
<br/>            Le Client s'engage en outre à ne pas rendre disponible ou distribuer des informations illicites ou répréhensibles (telles que des informations diffamatoires ou constitutives d'usurpation d'identité) ou encore nuisibles (telles que les virus). Dans le cas contraire, l’Exploitant sera en mesure de suspendre ou de résilier l'accès du Client au Site à ses torts exclusifs.
<br/>
<br/>
<br/>            <span className="bold">Article 6 – COMMANDE</span>
<br/>
<br/>            Le client a la possibilité de passer commande sur le site. Selon les produits choisis, la distance géographique avec l’Exploitant qui se réserve dans certains cas la livraison en personne à domicile du client, et les choix du client lors du paiement d’une commande, ce dernier se verra proposer ou non des choix sur la méthode de livraison. Ces choix constituent selon les cas énumérées ci-dessus la livraison à domicile, ou bien la livraison en point relais assuré dans ce cas par un service tiers, qui est en ce jour-ci MondialRelay.
<br/>
<br/>            Le client a l’obligation d’effectuer un paiement lors de la commande correspondant au prix total de la commande - qui comprend alors selon les cas les frais de livraison - sans possibilité d’effectuer un paiement en plusieurs fois. La commande ne sera préparée et livrée que lorsque le paiement sera effectif. 
<br/>
<br/>            Le client recevra un email lui confirmant que sa commande a été validée.
<br/>
<br/>            Dans l’hypothèse d’un défaut de fonctionnement du site où le client ne recevrait ni mail ni commande et qu’il aurait une preuve concrète que le montant total de la commande lui a été soutirée, il devra contacter l’Exploitant aux coordonnées indiquées plus haut, qui s’engage alors à lui livrer la commande selon une méthode de livraison qui sera discutée directement avec le client.
<br/>
<br/>
<br/>            <span className="bold">Article 7 – ABONNEMENT</span>
<br/>
<br/>            Dans l’hypothèse où le site proposerait un système d’abonnement, ce dernier serait alors soumis aux mêmes conditions de vente que la commande unique par paiement unique.
<br/>
<br/>
<br/>            <span className="bold">Article 8 – MODALITÉ PAIEMENT</span>
<br/>
<br/>            L’Exploitant utilise la solution de paiement en ligne Stripe, qui gère et stocke les méthodes de paiement du client.
<br/>            L’Exploitant ne détient ni ne stocke d’une quelconque façon les coordonnées de paiement du client.
<br/>
<br/>            Les commandes peuvent être payées en ayant recours à l’un des modes de paiement suivants :
<br/>
<br/>            - Paiement par carte bancaire. Le paiement s'effectue directement sur les serveurs bancaires sécurisés de la banque de l’Exploitant, les coordonnées bancaires du Client ne transitent pas sur le Site. Les coordonnées bancaires communiquées lors du paiement sont protégées par un procédé de cryptage SSL (Secure Socket Layer). De cette manière, ces coordonnées ne sont pas accessibles à des tiers lors de leur transfert vers les services de Stripe. 
<br/>
<br/>            La commande du Client est enregistrée et validée dès acceptation du paiement par la banque. Dans le cadre d’un abonnement, les paiements ont lieu le même jour que lors de l’achat initial du Client. La livraison s’effectuera à chaque fois selon la méthode choisie initialement par le Client lors du démarrage de l’abonnement. 
<br/>
<br/>            Le compte du Client sera débité du montant correspondant uniquement lorsque (i) les données de la carte bancaire utilisée auront été vérifiées et (ii) le débit aura été accepté par la banque ayant émis la carte bancaire.
<br/>
<br/>            L’impossibilité de débiter les sommes dues entraînera la nullité immédiate de la vente, mais n’entraînera pas en cas d’abonnement la nullité de la prochaine commande, sauf si l’Exploitant rencontre à nouveau une impossibilité de débiter la somme due. 
<br/>
<br/>            La carte bancaire peut notamment être refusée si elle est arrivée à expiration, si elle a atteint le montant maximal de dépense auquel le Client a droit ou si les données saisies sont incorrectes.
<br/>
<br/>            Dans l’hypothèse d’un défaut de paiement, l’Exploitant pourra éventuellement prévenir le Client par le moyen d’un email ou d’un appel téléphonique, mais n’en a pas l’obligation. 
<br/>
<br/>
<br/>            <span className="bold">Article 9 – PAIEMENT DE LA COMMANDE</span>
<br/>
<br/>            Les prix figurant sur le site internet sont des prix en Euros (€). L’Exploitant étant au statut auto-entreprise, les prix sont en (HT) "TVA non applicable, article 293B du CGI". Tout changement du statut de notre entreprise pourra conduire à une application de la TVA qui pourrait se répercuter sur les prix des produits, qui s’afficheront désormais en TTC.
<br/>            Le prix des Produits en vigueur lors de la commande est indiqué hors frais de livraison et de transport. En cas de promotion, l’Exploitant s'engage à appliquer le prix promotionnel à toute commande passée durant la période de la publicité faite pour la promotion. Cette période d’applicabilité de la promotion devra être indiquée de façon distincte par l’Exploitant aux moyens d’un message sur le site internet ou bien d’un email.
<br/>
<br/>            Dans l’hypothèse où le client utiliserait un code de promotion unique adressée au préalable par email par l’Exploitant, ce dernier s’engage à appliquer ladite promotion si le code de promotion n’a jamais été utilisé au préalable.
<br/>
<br/>            Les codes de promotions ne sont utilisables qu’au nombre de une par commande. Les codes de promotions ne sont donc pas cumulables lors d’une même commande. 
<br/>
<br/>            Si des frais de livraison ou de transport s’appliquent, ils seront ajoutés au prix total de la commande et indiqués de manière distincte avant la validation de la commande par le Client. Le montant total dû par le Client et son détail sont indiqués sur la page de confirmation de commande.
<br/>
<br/>
<br/>            <span className="bold">Article 10 – RÉSERVE DE PROPRIÉTÉ</span>
<br/>
<br/>            Rue Canine conserve la propriété des produits vendus jusqu’à l’encaissement de la totalité du prix, frais et taxes compris. 
<br/>
<br/>
<br/>            <span className="bold">Article 11 – EXPEDITION ET LIVRAISON</span>
<br/>
<br/>            Rue Canine propose plusieurs modes de livraison ; La livraison à domicile par l’Exploitant dans l’hypothèse d’une proximité géographique avec le Client ou bien la livraison par point relais, assurée en ce jour-ci par MondialRelay. Dans le cadre d’une livraison par point relais, le client aura la possibilité de choisir personnellement un point relais précis pour la réception de sa commande lors de la finalisation de la commande.
<br/>
<br/>            Les frais d’envoi sont ceux précisés lors de la validation de la commande. 
<br/>
<br/>            Rue Canine s’engage à livrer les produits dans un délai maximum de trente (30) jours après réception de la commande dans le cadre d’une livraison personnelle à domicile. Ce délai ne s’applique pas lors d’une livraison en point relais par MondialRelay. Ce dernier est donc le responsable en cas de délai anormalement élevé de livraison. 
<br/>
<br/>            Le délai de livraison dans le cadre d’une livraison à domicile inclut la préparation de la commande. 
<br/>
<br/>            Ces délais sont à titre indicatif et sont susceptibles d’être dépassés en période de surcharge d’activité du transporteur ou de l’Exploitant (ex : Noël). Le non-respect de ces délais indicatifs ne donnera lieu à aucune indemnisation de la part de l’Exploitant.
<br/>
<br/>            Si le Client refuse d'aller récupérer sa commande au point relais, les frais de retour seront à sa charge. Par ailleurs, si le client souhaite le renvoi d'une nouvelle commande, les frais d'envoi lui seront facturés. Ces frais correspondent aux frais que réclamerait un service tiers de livraison, que cette dernière soit une livraison à domicile ou bien par point relais.
<br/>
<br/>            Rue Canine s’engage à expédier la commande aux délais annoncés, sous réserve que le paiement n’a pas été refusé et qu’elle a été traitée dans les temps par Stripe et les banques respectives de l’Exploitant et du Client. 
<br/>
<br/>            Les Produits seront livrés à l'adresse indiquée par le Client lors de sa commande en cas de livraison à domicile, ou bien au point relais indiqué dans le cadre d’une livraison par point relais. Il faut donc qu’il vérifie que cette adresse ne contient pas d’erreur. Aucune responsabilité ne pourra être engagée si l’adresse donnée par le client est erronée. 
<br/>
<br/>            À la livraison, et ce sous un délai de 24h,  le client doit vérifier que les produits livrés sont conformes à sa commande et que le colis est bien fermé.
<br/>            En cas de problème, le client doit impérativement l’indiquer en contactant l’Exploitant aux coordonnées citées plus haut. Aucune réclamation ne serait prise en compte excédant 24h. 
<br/>            
<br/>
<br/>            <span className="bold">Article 12 – RETRACTATION</span>
<br/>
<br/>            Le client dispose d’un délai de quatorze jours ouvrables à compter de la livraison de la commande pour exercer son droit de rétractation. 
<br/>
<br/>            L’Exploitant adressera un accusé de réception de la demande de rétractation du Client par e-mail.
<br/>            Les frais de retour sont à la charge du Client.
<br/>
<br/>            Le Produit retourné doit l’être dans son emballage d'origine, en parfait état, propre à sa revente, non utilisé et avec l’ensemble des accessoires éventuels.
<br/>
<br/>            Le client sera automatiquement remboursé dans un délai de quatorze (14) jours à réception et contrôle des produits sur la plateforme Stripe de l’Exploitant.  Ce remboursement pourra être effectué par le même moyen de paiement que celui employé pour le Client. Il pourra ensuite repasser commande des produits de son choix. En acceptant les présentes Conditions Générales de Vente, le Client reconnaît expressément avoir été informé des modalités de rétractation.
<br/>
<br/>
<br/>            Article 13 – SERVICE CLIENTS
<br/>
<br/>            Le Client peut contacter Rue Canine par email à l’adresse contact@ruecanine.fr  ou bien par téléphone au 06 56 76 05 41, en indiquant son nom, numéro de téléphone, l’objet de sa demande et le numéro de la commande concernée.
<br/>
<br/>
<br/>            <span className="bold">Article 14 – GARANTIE</span>
<br/>
<br/>            L’ensemble des produits fournis par Rue Canine bénéficient de la garantie légale prévue par les articles 1641 et suivants le Code civil.
<br/>            En cas de non-conformité d’un produit, il pourra être retourné à Rue Canine qui le réceptionnera, fera l’échange ou effectuera le remboursement.
<br/>
<br/>            Le Client reconnaît que les caractéristiques et les contraintes d'Internet ne permettent pas de garantir la sécurité, la disponibilité et l'intégrité des transmissions de données sur Internet. Ainsi, l’Exploitant ne garantit pas que le Site et ses services fonctionneront sans interruption ni erreur de fonctionnement. En particulier, leur exploitation pourra être momentanément interrompue pour cause de maintenance, de mises à jour ou d'améliorations techniques, ou pour en faire évoluer le contenu et/ou leur présentation, ou en cas d’attaque informatique qui rendraient le site indisponible ou bien impossible à utiliser correctement, ou si les services tiers tels que les API de Stripe et de MondialRelay seraient indisponibles. 
<br/>
<br/>
<br/>            <span className="bold">Article 15 – DONNÉES PERSONNELLES </span>
<br/>
<br/>            Rue Canine s’engage à préserver les informations fournies par les clients.
<br/>            Chaque client est seul responsable de la confidentialité de ses identifiant et mot de passe. 
<br/>
<br/>
<br/>            <span className="bold">Article 16 – CONDITIONS</span>
<br/>
<br/>            Rue Canine se réserve le droit de modifier à tout moment le contenu du site ou services. Et de cesser de manière complète ou temporaire tout ou une partie du site. 
<br/>            Rue Canine conseille au client de capturer ou d’imprimer les Conditions Générales.
<br/>
<br/>
<br/>            <span className="bold">DROIT</span>
<br/>
<br/>            Les Conditions sont appliquées conformément au droit français.
<br/>
<br/>
<br/>            <span className="bold">RÉCLAMATION</span>
<br/>
<br/>            En cas de litige, vous devez vous adresser en priorité au service client de l'entreprise par email à l’adresse contact@ruecanine.fr  ou bien par téléphone au 06 56 76 05 41.
<br/>
<br/>
<br/>            <span className="bold">DROIT APPLICABLE</span>
<br/>
<br/>            Ces Conditions Générales sont régies, interprétées et appliquées conformément au droit français.
<br/>            
<br/>
<br/>            <span className="bold">ACCEPTATION DES CONDITIONS GÉNÉRALES </span>
<br/>
<br/>            Le Client reconnaît avoir lu attentivement les présentes Conditions Générales.
<br/>
</p>
</div>
)
}