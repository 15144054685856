import React, { useEffect, useState } from "react";
import Banner from "../components/banner";
import LabelPresentation from "../components/labelPresentation";
import Sponsorship from "../components/sponsorship";
import Promos from "../components/promos";
import Pros from "../components/pros";
import Footer from "../components/footer";
import FoodSelector from "../components/foodSelector";
import {Animal} from "../utils/generalTypes";
import ProductLinesPresentation from "../components/productLinesPresentation";
import IntroVideo from "../components/introVideo";
import Tutorial from "../components/tutorial";
import Commitments from "../components/commitments";
import "./landingPage.scss"
import { useAppSelector } from "../reduxFolder/store";

export default function LandingPage(){


    const landingMessage = useAppSelector(state=>state.general.generalWebsiteData.bannerMessage)

    const [animal, setAnimal] = useState<Animal>(Animal.Dog)

    useEffect(()=>{
        window.scrollTo(0,0);
    }, [])

    return (
        <div id="GeneralPage">
            <p className="landingMessage">{landingMessage}</p>
            <Banner setAnimal={setAnimal}/>
            <ProductLinesPresentation/>
            <IntroVideo/>
            <Commitments/>
            <Tutorial/>
            <FoodSelector lpAnimalChoice={animal} />
            {/* <Introduction/> */}
            <LabelPresentation/>
            <Sponsorship/>
            {/* <Promos/> */}
            <Pros/>
            <Footer/>
        </div>
    )
}