import React from "react";
import "./productLinesPresentation.scss"

export default function ProductLinesPresentation(){
    return(
        <div id="productLinesPresentation">
            <h2>NOS GAMMES DE PRODUITS</h2>

            <div className="productLineItem myfood">
                <h3>MyFood</h3>
                <img src={require("../images/products/myfood-line.png")} alt=""/>
                <p>
                    MyFood est un aliment complet pour chiens adultes de petites, moyennes et grandes races. <br/><br/>
                    Il contient de l’agneau et convient à tout chien de plus d’1 an ayant une activité physique normale. <br/>
                    Il existe également en gamme contenant du saumon.<br/><br/>

                    MyFood contient 33% d’agneau (ou de saumon). La viande est d’origine locale et ne contient pas d’additifs.
                </p>
                <a href="/products/get-products/food/all/all/premium" className="send">Voir MyFood</a>
            </div>

            <div className="productLineItem klicker">
                <h3>Klicker</h3>
                <img src={require("../images/products/klicker-line.png")} alt=""/>
                <p>
                    Klicker est la gamme Super-Premium de Rue Canine. Il répond à 90% des besoins de votre animal.<br/><br/>
                    La recette à l’agneau ou saumon représente une excellente source de protéines pour votre animal, 
                    contenant 80% de protéines et 20% de fruit et légumes.<br/><br/>

                    Klicker est une alimentation de très haute qualité, créée avec les nouvelles technologies de dernières générations FMIS 
                    (Fresh injection système) et double vaccuum. <br/>
                    Elle permet une meilleure assimilation des aliments et une digestion à 99% de la viande fraîche. 

                </p>
                <a href="/products/get-products/food/all/all/superpremium" className="send">Voir Klicker</a>
            </div>

            <div className="productLineItem treats">
                <h3>Friandises</h3>
                <img src={require("../images/products/treats.jpg")} alt=""/>
                <p>
                Rue Canine vous propose une gamme de pack de friandises mixtes 100% naturelles.<br/>
                Spécialement sélectionnées pour offrir un réel plaisir à vos animaux de compagnie. <br/><br/>

                Nos friandises sont riches en protéine pauvre, en matière grasse et ne contiennent aucun additif ni conservateur. Elles seront le plus grand plaisir de vos chiens. 
                <br/><br/>
                Notre page peut être mise à jour à chaque arrivage selon les nouveautés. De plus les tailles de nos différentes friandises peuvent varier selon nos différents choix de pack. 
                <br/><br/>
                Les bienfaits de la mastication sur votre chien ? 
                <br/><br/>
                La mastication a plusieurs bienfaits, elle permet notamment de relaxer votre chien et de l’occuper durant les moments de repos. 
                <br/><br/>
                Elle permet une bonne santé bucco-dentaire et également de lutter contre le tartre de façon naturelle. 
                <br/><br/>
                Selon plusieurs études, la mastication du chien est un besoin essentiel et permet à votre animal de se dépenser physiquement. 
                20 minutes de mastication équivaut à 1h de balade. 
                <br/><br/>
                Nous vous conseillons de donner une seule friandise à la fois, sur une période de 24h, pour voir si votre chien supporte bien nos produits.
                <br/>
                </p>
                <a href="/products/get-products/treat/all/all/all" className="send">Voir les friandises</a>
            </div>

            <div className="productLineItem accessory">
                <h3>Accessoires</h3>
                <img src={require("../images/products/colliers.jpg")} alt=""/>
                <p>

                Rue Canine propose pour vos chiens une vaste gamme de colliers, de couleurs, de matières et de tailles différentes.<br/><br/>

                Nos colliers sont fabriqués avec des matières de haute qualité robuste et solide. <br/>
                De qualité premium, confortables et offrant une double protection du cou.<br/>

                Cliquez sur le bouton ci-dessous pour trouver votre bonheur.<br/>

                </p>
                <a href="/products/get-products/accessory/all/all/premium" className="send">Voir les accessoires</a>
            </div>

            <div className="productLineItem accessoryPremium">
                <h3>Accessoires Prestige</h3>
                <img src={require("../images/products/colliers-prestige.jpeg")} alt=""/>
                <p>
                    Rue Canine propose pour vos chiens une vaste gamme de colliers prestige, de style et de matériaux divers.<br/><br/>

                    Nos colliers sont fabriqués avec des matières de haute qualité robuste et solide. <br/>
                    De qualité prestige, confortables et offrant une double protection du cou.<br/><br/>

                    Le style des colliers varie selon vos préférences.<br/><br/>

                    Cliquez sur le bouton ci-dessous pour trouver votre bonheur.<br/>

                </p>
                <a href="/products/get-products/accessory/all/all/superpremium" className="send">Voir la gamme Prestige</a>
            </div>

            {/* <div className="productLineItem mystic">
                <h3>Mystic</h3>
                <img src={require("../images/products/mystic-line.png")} alt=""/>
                <p>
                    Mystic est un aliment complet pour chiens adultes de petites, moyennes et grandes races. <br/><br/>
                    Il contient de l’agneau et convient à tout chien de plus d’1 an ayant une activité physique normale. <br/>
                    Il existe également en gamme contenant du saumon.<br/><br/>

                    Mystic contient 33% d’agneau (ou de saumon). La viande est d’origine locale et ne contient pas d’additifs.
                </p>
                <a href="" className="send">Voir Mystic</a>
            </div> */}
        </div>
    )
}