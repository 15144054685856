import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { BuyingType, CartItem, IGeneralWebsiteData, Order, UserDataServer } from "../utils/generalTypes";

export type AlertType = "simple" | "yesno"
export interface GeneralInterface{
    isLoggedin:boolean;
    userData:UserDataServer;
    cart:CartItem[];
    generalWebsiteData: IGeneralWebsiteData;
    alertModalText:string;
    alertModalDisplay:boolean;
    alertModalType: AlertType;
}



let orders:Order[] = [
    {
        products:[],
        orderType:BuyingType.simpleBuy,
        frequency:5,
        onGoing:true,
        date:new Date().toLocaleDateString(),
        totalPrice:150
    },
    {
        products:[],
        orderType:BuyingType.subscription,
        frequency:5,
        onGoing:true,
        date:new Date().toLocaleDateString(),
        totalPrice:150
    }
]


const initialState:GeneralInterface = {
    isLoggedin:false,
    userData:{
        email:"rdxEmail",
        name:"rdxName",
        isAdmin:false,
        address:"rdxAddress",
        postalCode:"rdxPostalCode",
        city:"rdxCity",
        tel:"rdxTel",
        userId:"rdxUserId",
        previousOrders:orders
    },
    cart:[],
    generalWebsiteData:{
        bannerMessage:"Bienvenue sur le site de Rue Canine",
        deliveryFee:12.2
    },
    alertModalText:"",
    alertModalDisplay:false,
    alertModalType:"simple"
}

export const GeneralSlice = createSlice({
    name:"general",
    initialState,
    reducers:{
        changeLoggedin: (state, action:PayloadAction<{loggedin:boolean}>)=>{
            state.isLoggedin = action.payload.loggedin
            return state
        },
        changeUserData:(state, action:PayloadAction<{newUserData:UserDataServer}>)=>{
            state.userData = {...action.payload.newUserData}
            return state
        },
        changeCart:(state, action:PayloadAction<{items:CartItem[]}>)=>{
            state.cart = action.payload.items
            return state
        },
        changeGeneralData: (state, action:PayloadAction<{generalWebsiteData:IGeneralWebsiteData}>)=>{
            state.generalWebsiteData = {...action.payload.generalWebsiteData}
            return state
        },
        changeAlertModalText: (state, action:PayloadAction<{text:string}>)=>{
            state.alertModalText = action.payload.text
            state.alertModalDisplay = true
            return state
        },
        changeAlertModalDisplay: (state, action:PayloadAction<{display:boolean}>)=>{
            state.alertModalDisplay = action.payload.display
            return state
        },
        changeAlertModalType: (state, action:PayloadAction<{type:AlertType}> )=>{
            state.alertModalType = action.payload.type;
            return state
        }
    }
})

export default GeneralSlice.reducer
export const {changeLoggedin, changeUserData, changeGeneralData, changeCart, changeAlertModalDisplay, changeAlertModalText, changeAlertModalType} = GeneralSlice.actions