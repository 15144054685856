import React, { useEffect, useState } from "react";
import {Product } from "../utils/generalTypes";
import { Link } from "react-router-dom";
import "./productsList.scss"

export default function ProductsList(){

    const [products, setProducts] = useState<Product[]>([])

    useEffect(()=>{
        window.scrollTo(0,0);
        const getProducts = async ()=>{
            const urlPath:string[] = window.location.pathname.slice(1).split("/").slice(2)
            console.error(urlPath)

            const productsList = await (
                await fetch("/products/get-products", {
                    method:"POST",
                    headers:{
                        "Content-Type":"application/json"
                    },
                    body:JSON.stringify({
                        productType:urlPath[0],
                        animal:urlPath[1],
                        age:urlPath[2],
                        lineType:urlPath[3]
                    })
                })
            ).text()
            if(productsList.includes('{')){
                setProducts(JSON.parse(productsList).productsList)
            }
        }
        getProducts()
    }, [])

    return(
        <div id="ProductsListPage">
            <h1>Votre sélection</h1>
            <div className="productsList">
                {
                    products && products.length ? products.map((product, index)=>{
                        return(
                            <div className="product" key={index}>
                                <div className="imgContainer" style={{backgroundImage:`url("/get-image${product.imgSrc}")`}}></div>
                                <div className="row">
                                    <p className="title">{product.title}</p>
                                    <p className="weight" style={{display: product.availableLines && product.availableLines[0].weight ? "block":"none" }}>{
                                        product.availableLines?.[0].weight ?? 0
                                    } kg</p>
                                </div>
                                <p className="description">{product.description}</p>
                                <div className="row">
                                    <p className="price">{product.availableLines ? product.availableLines[0].price : product.price} €</p>
                                    <Link to={`/products/product-detail/${product.id}`} state={{previousPageProduct:product}} className="seeButton">Voir</Link>
                                </div>
                            </div>
                        ) 
                    }) : <p>Aucun produit ne correspond à votre attente</p>
                }
            </div>
        </div>
    )
}