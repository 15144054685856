import React from "react";
import "./labelPresentation.scss"

export default function LabelPresentation(){
    return(
        <div id="LabelPresentation">
            <div className="labelCardContainer">
                <div className="labelCard">
                    <img src={require("../images/labels/bio.png")} alt=""/>
                    <h3>Des ingrédients bios qualitatifs</h3>
                </div>
            </div>

            <div className="labelCardContainer">
                <div className="labelCard">
                    <img src={require("../images/labels/quality-control.png")} alt=""/>
                    <h3>Un contrôle vétérinaire sans faille</h3>
                </div>
            </div>

            <div className="labelCardContainer">
                <div className="labelCard">
                    <img src={require("../images/labels/team.png")} alt=""/>
                    <h3>Une équipe experte et passionnée</h3>
                </div>
            </div>
        </div>
    )
}