import React, { useState } from "react";
import "./loginPage.scss"
import { IUserRegisterData} from "../utils/generalTypes";
import { useLocation, useNavigate } from "react-router-dom";
import { displayAlertModal, setLoggedin, setUserData } from "../utils/generalMethods";

enum pageType{
    login,
    register,
    forgotpassword
}

export default function LoginPage(){

    const [loginType, setLoginType] = useState<pageType>(pageType.login)

    const [name, setName] = useState<string>("")
    const [email, setEmail] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const [confirmPassword, setConfirmPassword] = useState<string>("")
    const [tel, setTel] = useState<string>("")
    const [address, setAddress] = useState<string>("")
    const [postalCode, setPostalCode] = useState<string>("")
    const [city, setCity] = useState<string>("")

    const navigate = useNavigate()
    const location = useLocation()


    const register = async ()=>{
        const userData:IUserRegisterData = {
            email,
            name,
            address,
            postalCode,
            city,
            tel,
            password,
            confirmPassword,
        }

        const registerReq = await(
            await fetch("/user/register", {
                method:"POST",
                headers:{
                    "Content-Type":"application/json",
                },
                body:JSON.stringify(userData)
            })
        ).text()
        if(registerReq.includes("terminée")){
            setLoginType(pageType.login)
        }
        displayAlertModal(registerReq)
    }

    const login = async()=>{
        const userData = {
            email,
            password
        }

        const loginReq = await(
            await fetch("/user/login", {
                method:"POST",
                headers:{
                    "Content-Type":"application/json",
                },
                body:JSON.stringify(userData)
            })
        ).text()
        if(loginReq.includes("{")){
            setUserData(JSON.parse(loginReq))
            setLoggedin(true)
            location.state && location.state.fromPayment ? navigate("/cart") : navigate("/")
        }
        else{
            displayAlertModal(loginReq)
        }
    }

    const resetPassword = async ()=>{

        const resetReq = await(
            await fetch("/user/reset-password", {
                method:"POST",
                headers:{
                    "Content-Type":"application/json",
                },
                body:JSON.stringify({email})
            })
        ).text()
        displayAlertModal(resetReq)
        if(resetReq.includes("nouveau")){
            setLoginType(pageType.login)
        }
    }

    switch(loginType){

        case pageType.register:

            return(
                <div id="LoginPage">
                    <h2>Inscription</h2>
                    <input name="name" type="text" placeholder="Nom Prénom" value={name} onChange={(e)=>{setName(e.target.value)}}/>
                    <input name="email" type="email" placeholder="E-mail" value={email} onChange={(e)=>{setEmail(e.target.value)}}/>
                    <input name="tel" type="text" placeholder="Téléphone" value={tel} onChange={(e)=>{setTel(e.target.value)}}/>
                    <input name="adress" type="text" placeholder="N° rue, voie.." value={address} onChange={(e)=>{setAddress(e.target.value)}}/>
                    <input name="adress" type="text" placeholder="Code postal" value={postalCode} onChange={(e)=>{setPostalCode(e.target.value)}}/>
                    <input name="adress" type="text" placeholder="Ville" value={city} onChange={(e)=>{setCity(e.target.value)}}/>
                    <input name="password" type="password" placeholder="Mot de passe" value={password} onChange={(e)=>{setPassword(e.target.value)}}/>
                    <input name="password" type="password" placeholder="Confirmer le mot de passe" value={confirmPassword} onChange={(e)=>{setConfirmPassword(e.target.value)}}/>
                    <button className="send" onClick={register}>Valider</button>
                    <button className="other" onClick={()=>{setLoginType(pageType.login)}}>Déjà inscrit ? Connectez-vous !</button>
                </div>
            );


        case pageType.forgotpassword:

            return(
                <div id="LoginPage">
                    <h2>Mot de passe oublié</h2>
                    <p>Nous enverrons à votre adresse mail un mot de passe temporaire. Veuillez vous connecter et changer ensuite votre mot de passe
                        depuis votre espace client.
                    </p>
                    <input name="email" type="email" placeholder="E-mail" value={email} onChange={(e)=>{setEmail(e.target.value)}}/>
                    <button className="send" onClick={resetPassword}>Recevoir</button><br/>
                    <button className="other" onClick={()=>{setLoginType(pageType.login)}}>Connectez-vous</button>
                </div>
            );


        case pageType.login:  
        default:

            return(
                <div id="LoginPage">
                    <h2>Connexion</h2>
                    <input name="email" type="email" placeholder="E-mail" value={email} onChange={(e)=>{setEmail(e.target.value)}}/>
                    <input name="password" type="password" placeholder="Mot de passe" value={password} onChange={(e)=>{setPassword(e.target.value)}}/>
                    <button className="send" onClick={login}>Se connecter</button>
                    <button className="other" onClick={()=>{setLoginType(pageType.register)}}>Pas encore inscrit ? Cliquez ici !</button><br/>
                    <button className="other" onClick={()=>{setLoginType(pageType.forgotpassword)}}>Mot de passe oublié</button>
                </div>
            );


    }
}