import React from "react";
import { CartItem } from "../utils/generalTypes";

export default function Summary({ productList }: { productList: CartItem[] }) {
  return (
    <div className="summary">
      {productList.map((productItem, index) => {
        return (
          <div className="cartItem row" key={index}>
            <img src={`/get-image${productItem.product.imgSrc}`} alt="" />
            <div className="cartItemInfos">
              <h5>{productItem.product.title}</h5>
              <div className="row">
                <p style={{ display: productItem.product.availableLines ? "block" : "none" }}>
                  {productItem.quantity} x{" "}
                  {productItem.product.availableLines && productItem.selectedLineIndex !== undefined
                    ? productItem.product.availableLines[productItem.selectedLineIndex].weight + " kg"
                    : ""}
                </p>
                <p>
                  {productItem.quantity * (productItem.product.availableLines?.[productItem.selectedLineIndex ?? 0].price ?? productItem.product.price ?? 0)}€
                </p>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
