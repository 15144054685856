import React from "react";
import "./presentationPage.scss"

export default function Presentation(){
    return(
        <div className="presentationPage">
            <h2>Rue Canine</h2>
            <img src={require("../images/presentation/presentation.jpeg")}  alt="" />
            <p>
            Nous sommes la société Rue Canine distributeur exclusif de Hermos Pet Food, une usine familiale qui
a commencé ses activités en février 2017.<br/><br/>


Nous nous sommes rendus compte que la plupart de nos connaissances ayant des animaux de
compagnie pensaient nourrir leur chiens ou chats avec une alimentation de qualité tout en leur donnant
finalement de l’alimentation qui pouvait être très dangereuse pour leur santé, dont certaines sont
des marques très connues dont nous tairons le nom.<br/><br/>


C’est pour cela que nous avons décidé de reprendre la distribution en France de nos propres produits
qui respectent nos animaux et qui sont conçues de la même façon que la production de nourriture destinée à la consommation
humaine.<br/><br/>


Basé dans le Val-D’oise près de Paris, nous sommes dans la capacité de vous fournir en alimentations
secs pour chiens et chats (croquettes) ou alimentation humide (pâté), friandises naturelles, accessoires, et ce sur
toute la France. De plus nous avons plusieurs gammes sous plusieurs marques. Klicker dans le
segment super premium sans céréales, Myfood dans le segment premium.<br/><br/>


Nous livrons aussi bien des animaleries que des clubs canins.<br/><br/>


Notre réactivité, notre expertise seront votre réussite en travaillant avec nous. De plus notre statut de
distributeur nous permet de vous proposer des tarifs attractifs.<br/><br/>


Hermos Pet Food a commencé ses activités en février 2017 en tant que première usine de produits
alimentaires secs en Turquie. En menant ses activités avec le plus grand soin dans le domaine de la
recherche et du développement, notre entreprise se tient au courant des développements
scientifiques dans le monde et, réalise des activités de développement de produits avec de
nombreuses universités internationales. A la suite de cette activité, pour la première fois en Turquie, la
technologie FMIS (Liquefied Fresh Meat Injection System) a été introduite dans notre usine de
production.<br/><br/>


Notre société fournit des produits sous ses propres marques à des milliers de points de vente à
travers le pays, et a réussi à promouvoir ses marques à l'étranger, en les exportant dans plus de
quarante pays à travers le monde.<br/><br/>


Notre usine a une capacité de production annuelle de 40 000 tonnes. Les nutriments utilisés dans
notre entreprise, à partir desquels nous produisons des aliments secs de haute qualité, sains et
savoureux pour nos amis à quatre pattes, sous la forme la plus fraîche, sous réserve de conditions de
transport et de température appropriées et sans perturber la chaîne alimentaire, sont livrés à notre
usine de production par nos fournisseurs agréés et certifiés. <br/>Conçue selon les critères de production
d'aliments pour l'homme, notre cuisine de production maintient également le système de Gestion de la
Qualité ISO 9001, le Système de Gestion Environnementale ISO 14001, Système de Gestion de la
sécurité alimentaire ISO 22000, Système de Gestion de la Sécurité Alimentaire GMP et des bonnes
pratiques agricoles, Système de Gestion de la Qualité et de la Sécurité Alimentaire BRC adapté à la
consommation humaine. <br/>La BRC d'origine britannique est une norme internationalement reconnue
pour les industries de l'alimentation, de l'emballage et des biens de consommation qui repose sur la
traçabilité depuis les exigences des fournisseurs, du personnel et des infrastructures des installations
de transformation des aliments jusqu'au consommateur final. En plus de tout ce qui précède, dans
notre cuisine de production, la production est effectuée à l'aide du premier système d'injection de
viande fraîche liquéfiée de Turquie (FMIS).<br/><br/>

Nos valeurs : le meilleur pour nos amis à quatre pattes
- Communiquer et créer un lien avec nos partenaires et propriétaires d’animaux<br/>
- Devenir une entreprise créative, innovante, et productive en adhérant aux principes
d’honnêteté, de transparence, d’équité et de respect en utilisant la technologie la plus
avancée et en accordant une grande importance aux activités de recherche et
développement.<br/>
- Protéger l’environnement et maintenir un mode de vie durable afin de fournir une nutrition
naturelle<br/>
- Traiter nos partenaires commerciaux comme une partie prenante importante pour maintenir le
succès commercial et adhérer à l’exactitude, l’éthique et l’honnêteté dans la concurrence avec
nos concurrents.<br/>
Notre mission : produire et proposer des aliments sains et savoureux pour chats et chiens à partir
de matières premières respectueuses de l’environnement, et les proposer à des prix abordables.<br/><br/>

Nos technologies :<br/>
- Système d'injection de viande fraîche<br/>
- La viande est amenée à l'usine sans perturber les anneaux de refroidissement.<br/>
- La viande est divisée en particules sans ajout de matières supplémentaires.<br/>
- La viande se transforme en une formule liquide fraîche<br/>
- Lorsque le repas obtenu à l'issue de ce procédé se présente sous forme de pâte, il est injecté dans
l'aliment.<br/>
- La viande fraîche est répartie de manière homogène entre les aliments.<br/>
Le taux de digestibilité de la viande liquide est de 99 %.<br/>
- L'odeur et la viande est répartie dans chaque croquettes<br/>
- La composition des granulés alimentaires atteint un point attractif pour les animaux.
De plus afin de préserver tous les nutriments, vitamines et minéraux présent dans nos aliments nos
croquettes sont cuites à basse température<br/>
- Double aspiration
Dans notre cuisine, le processus de rendu des sauces et des graisses se fait sous vide sans les
toucher avec les mains. La technologie de double aspiration mentionnée ci-dessus est la première du
genre en Turquie et dans le monde, et dans ce processus, les graisses et les sauces sont distribuées
dans le produit jusqu'au point le plus profond du produit pour garantir que toutes les molécules du
produit obtiennent le même délice. Cela garantit que nos amis les animaux de compagnie profitent du
même délice dans tous les granulés alimentaires.<br/><br/>

Contrôle qualité et test vétérinaires :
Dans notre usine, des analyses sont effectuées afin de vérifier la qualité. Le processus d'analyse
commence par la sélection des matières premières. Dès réception des matières premières
sélectionnées, un échantillon est obtenu en fonction du poids et du type, et l'échantillon est mesuré.
Nos mesures des matières entrantes : protéines, graisses, cendres, cellulose et humidité. Les
matières premières obtenues auprès de nos fournisseurs certifiés sont examinées dans nos
laboratoires et dans des laboratoires externes accrédités. <br/>Dans notre laboratoire, nous examinons
dans un premier temps (Salmonella, A. monocytogonus et Enterobacter) en plus des moisissures et
des levures. Pendant certaines périodes, nous testons régulièrement la toxicité en plus des pesticides.
Le produit est accepté après analyse de la toxicité, des métaux lourds et de la dioxine. Nous obtenons
également des lettres de déclaration de nos fournisseurs concernant les produits ne provoquant pas
d'infection résultant de substances chimiques et radioactives. <br/>La graisse utilisée dans notre pays est
considérée pour nous comme l'une des matières premières les plus importantes pour enrober le
produit et lui donner du goût. Par conséquent, tous les produits entrant dans l'usine sont évalués en
termes de valeurs (d'acidité libre) et de peroxyde. <br/>Si ces valeurs sont appropriées, le produit est
accepté. Au cours du processus de production, des échantillons sont prélevés à différents points, le
produit est analysé et le processus de production est orienté en conséquence.
Ceci est effectué pour tous les produits et avant la fin du processus de production du produit.
Nous nous soucions également de la qualité de l’eau utilisée autant que du produit. Ainsi, lorsque
nous analysons l’eau, nous prenons les normes relatives à l’eau potable comme critères de base.<br/>
Pour éviter la transmission d’infections par voie aérienne et par les machines, le nombre de micro-
organismes, de moisissures et de levures est examiné, en plus d’analyser les mains des travailleurs.
Dans la dernière étape, une analyse des éléments nutritionnels est effectuée afin d'analyser le produit
final. En plus de l'analyse des éléments nutritionnels, des analyses qui affectent la sécurité et la
sécurité alimentaire sont effectuées. Les analyses sont répétées selon les étapes.<br/><br/>

Les tests effectués sont les suivants : Salmonella, I. monocytogonus, Enterobacter, levure-moisissure,
aflatoxine totale et espèces de toxines, dicocine, acidité libre et peroxyde.<br/>
Pour éviter l'infection par les conteneurs ou durant le transport les produits sont testés par des
vétérinaires à la frontières et certifié que la composition correspond bien à ce que le sac contient.
Ce qui fait aussi notre force de vente car nous vendons des produits testé et validé<br/>
            </p>
        </div>
    )
}