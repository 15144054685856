import React from "react";

export default function PrivacyPage(){
    return(
        <div className="otherTextPage">
            <h2>Vie privée</h2>

            <p>
                Rue Canine confirme que le traitement des données est conforme aux normes RGPD. <br/><br/>

                Les données demandées au client lors de la création du compte sont les suivantes : <br/><br/>

                - Nom et Prénom<br/>
                - Email<br/>
                - Téléphone<br/>
                - Adresse<br/>
                - Code postal<br/>
                - Ville<br/>
                - Un mot de passe et sa confirmation<br/><br/>

                Lors d’un achat, le client pourra se voir demander ses informations de coordonnées bancaires, auquel cas ces derniers seront envoyées à Stripe, qui a alors la responsabilité du traitement de ces données. Rue Canine nie toute responsabilité en cas de quelconque problème rencontré par Stripe.
                Le site utilise également la solution de mail SendGrid, et nie également toute responsabilité en cas de problème rencontré par SendGrid.<br/><br/>

                Nos données sont stockées de façon sécurisée. <br/>
                À tout moment, depuis son espace compte,  le client peut modifier son téléphone, adresse, code postal, ville et son mot de passe.<br/>
                Pour modifier son nom et prénom ainsi que son adresse e-mail, ou bien pour supprimer définitivement son compte, il devra contacter l’administrateur du site aux coordonnées indiquées dans les mentions légales.<br/>

            </p>
        </div>
    )
}