import React, { useEffect, useState } from "react";
import { Product, ProductDetailTabType, ProductType, CartItemServer } from "../utils/generalTypes";
import { ingredientsDescriptions, ingredientsTitles } from "../utils/generalData";
import ProductDetailTab from "./productDetailTab";
import "./productDetail.scss"
import {useLocation } from "react-router-dom";
import { displayAlertModal, setCartItemsLocal } from "../utils/generalMethods";

export default function ProductDetail(){

    const increaseQuantity = ()=>{
        setQuantity(quantity + 1)
    }

    const decreaseQuantity = ()=>{
        if(quantity > 1){
            setQuantity(quantity - 1)
        }
    }

    const addCartItem= async () =>{
        if(product){
            const newCartItem:CartItemServer = {
                product:product.id,
                quantity,
                selectedLineIndex:lineIndex,
            }

            const addItemReq = await(
                await fetch("/cart/add-cart-item",{
                    method:"POST",
                    headers:{
                        "Content-Type":"application/json"
                    },
                    body:JSON.stringify(newCartItem)
                })
            ).text()
            
            if(addItemReq.includes("{")){
                setCartItemsLocal(JSON.parse(addItemReq).cart)
                displayAlertModal("Le produit a été ajouté au panier. Vous pouvez retrouver le panier en haut de l'écran.")
            }
            else displayAlertModal(addItemReq)
            
        }
        return false
    }

    const location = useLocation()

    let previousPageProduct:Product | undefined = undefined;
    if(location.state && location.state.previousPageProduct){
        previousPageProduct = location.state.previousPageProduct
    }

    const [lineIndex, setLineIndex] = useState<number>(0)
    const [quantity, setQuantity] = useState<number>(1)
    const [product, setProduct] = useState<Product | undefined>(previousPageProduct)


    let tabsToDisplay:ProductDetailTabType[] = ["description", "everythingabout"]

    if(product && product.productType === ProductType.Food){
        tabsToDisplay.splice(-1, 0, "composition")
        tabsToDisplay.splice(-1, 0, "transition")
    }
    if(product && product.productType === ProductType.Food && product.hasDermaSupport) tabsToDisplay.splice(-1, 0, "dermasupport")

    


    useEffect(()=>{
        const getProductDetails = async()=>{
            const fetchedProduct = JSON.parse(await (
                await fetch("/products/product-detail", {
                    method:"POST",
                    headers:{
                        "Content-Type":"application/json"
                    },
                    body:JSON.stringify({
                        id:window.location.pathname.slice(1).split("/")[1]
                    })
                })
            ).text())
            setProduct(fetchedProduct)
        }
        if(!product) getProductDetails()
    }, []) 


    if(product){
        return(
            <div id="ProductDetailPage">
                <div className="imgContainer">
                    <img src={`/get-image${product.imgSrc}`} alt="MyFood"/>
                </div>
                <h1>{product.title}</h1>
                <div className="availableLines" style={{display: product.availableLines && product.stockAvailable ? "flex": "none"}}>
                    {
                        product.productType === ProductType.Food && product.availableLines ?  product.availableLines.sort((a, b)=>{return a.weight - b.weight}).map((line, index)=>{
                            return(
                                <label key={index}>
                                    <input type="radio" name="productline" defaultChecked={index === 0} onChange={(e)=>{setLineIndex(index)}} />
                                    <div className="lineChoiceContainer">
                                        <p>{line.weight === 0 ? "Échantillon" : line.weight + " kg"}</p>
                                    </div>
                                </label>
                            )
                        }) : ""
                    }
                </div>
    
                <div className="buyingTypeContainer" style={{display: product.stockAvailable ? "block":"none"}}>
                    <div className="buyingTypeContent">
                        <div className="row">
                            <h3>Quantité</h3>
                            <div className="quantityCounter">
                                <button onClick={decreaseQuantity}>-</button>
                                <p>{quantity}</p>
                                <button onClick={increaseQuantity}>+</button>
                            </div>
                        </div>
    
                        <div className="row">
                            <h3>Prix total</h3>
                            <p>{(product.availableLines ? product.availableLines[lineIndex].price: product.price ?? 0)* quantity} €</p>
                        </div>
    
                    </div>
                </div>
    
                {
                    product.stockAvailable ? <button onClick={addCartItem} className="send">Ajouter au panier</button> : <p className="noStock">Rupture de stock</p>
                }
    
                <div className="ingredientsContainer" style={{display: product.productType !== ProductType.Accessory && product.ingredients ? "block":"none"}}>
                    <h2>INGRÉDIENTS</h2>
    
                    {
                        product.ingredients?.map((i, key)=>{
                            return(
                                <div className="ingredient" key={key}>
                                    <div className="ingredientLeft">
                                        <img src={require(`../images/ingredients/${i}.png`)} alt={i} />
                                        <h3>{ingredientsTitles[i]}</h3>
                                    </div>
                                    <p className="description">{ingredientsDescriptions[i]}</p>
                                </div>
                            )
                        })
                    }
                </div>
    
                {
                    tabsToDisplay.map((tab, key)=>{
                        return <ProductDetailTab tab={tab} product={product} key={key}/>
                    })
                }
    
            </div>
        )
    }
    else{
        return <p></p>
    }
}

