import { changeAlertModalDisplay, changeAlertModalText, changeAlertModalType, changeCart, changeGeneralData, changeLoggedin, changeUserData } from "../reduxFolder/generalSlice"
import { store } from "../reduxFolder/store"
import { CartItem, IGeneralWebsiteData, UserDataServer } from "./generalTypes"

export const displayAlertModal = (text:string)=>{
    store.dispatch(changeAlertModalType({type:"simple"}))
    store.dispatch(changeAlertModalText({text:text}))
}

export let confirmCallbackFunction = ()=>{}

export const displayConfirmModal = (text:string, callback:()=>Promise<void>)=>{
    confirmCallbackFunction = callback
    store.dispatch(changeAlertModalType({type:"yesno"}))
    store.dispatch(changeAlertModalText({text:text}))
}

export const hideAlertModal = ()=>{
    store.dispatch(changeAlertModalDisplay({display:false}))
}

export const setGeneralDataLocal = (generalWebsiteData: IGeneralWebsiteData)=>{
    store.dispatch(changeGeneralData({generalWebsiteData}))
}

export const setCartItemsLocal = (cart:CartItem[])=>{
    store.dispatch(changeCart({items:cart}))
}

export const setLoggedin = (loggedin:boolean)=>{
    store.dispatch(changeLoggedin({loggedin}))
}

export const setUserData = (newUserData:UserDataServer)=>{
    store.dispatch(changeUserData({newUserData}))
}



