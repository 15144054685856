import React, { useEffect, useState } from "react";
import {
  ACCESSORY_STR,
  ADULT_STR,
  Age,
  ALL_STR,
  Animal,
  CAT_STR,
  DailyQuantity,
  DOG_STR,
  FOOD_STR,
  Ingredient,
  LineType,
  PREMIUM_STR,
  Product,
  ProductLine,
  ProductType,
  SUPERPREMIUM_STR,
  TREAT_STR,
  YOUNG_STR,
} from "../utils/generalTypes";
import "./adminProductHandler.scss";
import { displayAlertModal } from "../utils/generalMethods";
import { randomFoodProduct, randomOtherProduct } from "../utils/generalData";

const stringToAgeEnum: Record<string, Age> = {
  [ADULT_STR]: Age.Adult,
  [YOUNG_STR]: Age.Young,
  [ALL_STR]: Age.All,
};

const stringToAnimalEnum: Record<string, Animal> = {
  [DOG_STR]: Animal.Dog,
  [CAT_STR]: Animal.Cat,
  [ALL_STR]: Animal.All,
};

const stringToProductType: Record<string, ProductType> = {
  [ACCESSORY_STR]: ProductType.Accessory,
  [FOOD_STR]: ProductType.Food,
  [TREAT_STR]: ProductType.Treat,
  [ALL_STR]: ProductType.All,
};

const stringToLineType: Record<string, LineType> = {
  [PREMIUM_STR]: LineType.Premium,
  [SUPERPREMIUM_STR]: LineType.SuperPremium,
  [ALL_STR]: LineType.All,
};

const ingredientsList: Record<Ingredient, string> = {
  agneau: "Agneau",
  carotte: "Carotte",
  citrouille: "Citrouille",
  epluchures: "Épluchures",
  grenade: "Grenade",
  huileolive: "Huile d'Olive",
  levure: "Levure",
  lin: "Lin",
  myrtilles: "Myrtilles",
  pois: "Pois",
  poulet: "Poulet",
  riz: "Riz",
  saumon: "Saumon",
  cassava: "Cassava",
};

export type mode = "add" | "edit";

export default function AdminProductHandler({
  display,
  mode,
  product,
  setHandlerMode,
  setHandlerDisplay,
  setProductsListReload,
  productsListReload,
}: {
  display: boolean;
  mode: mode;
  product: Product;
  setHandlerMode: React.Dispatch<React.SetStateAction<mode>>;
  setHandlerDisplay: React.Dispatch<React.SetStateAction<boolean>>;
  setProductsListReload: React.Dispatch<React.SetStateAction<number>>;
  productsListReload: number;
}) {
  const [currentProduct, setCurrentProduct] = useState<Product>(product);

  const [dqWeight, setDqWeight] = useState<number>(0);
  const [dqRangeOne, setDqRangeOne] = useState<number>(0);
  const [dqRangeTwo, setDqRangeTwo] = useState<number>(0);

  const [productLineWeight, setProductLineWeight] = useState<number>(0);
  const [productLinePrice, setProductLinePrice] = useState<number>(0);

  const addDailyQuantity = () => {
    setCurrentProduct({
      ...currentProduct,
      dailyQuantities: currentProduct.dailyQuantities
        ? [...currentProduct.dailyQuantities, { weight: dqWeight, range: [dqRangeOne, dqRangeTwo] }]
        : [{ weight: dqWeight, range: [dqRangeOne, dqRangeTwo] }],
    });
    setDqWeight(0);
    setDqRangeOne(0);
    setDqRangeTwo(0);
  };

  const removeDailyQuantity = (dailyQuantity: DailyQuantity) => {
    setCurrentProduct({
      ...currentProduct,
      dailyQuantities: currentProduct.dailyQuantities?.filter((dq) => {
        return dq !== dailyQuantity;
      }),
    });
  };

  const addProductLine = () => {
    setCurrentProduct({
      ...currentProduct,
      availableLines: currentProduct.availableLines
        ? [...currentProduct.availableLines, { weight: productLineWeight, price: productLinePrice }]
        : [{ weight: productLineWeight, price: productLinePrice }],
    });
    setProductLinePrice(0);
    setProductLineWeight(0);
  };

  const removeProductLine = (productLine: ProductLine) => {
    setCurrentProduct({
      ...currentProduct,
      availableLines: currentProduct.availableLines?.filter((line) => {
        return line !== productLine;
      }),
    });
  };

  const setProductType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue: string = e.target.value;
    if (mode === "add") {
      setCurrentProduct({ ...(newValue === "food" ? randomFoodProduct : randomOtherProduct), productType: stringToProductType[newValue] });
    }
  };

  const addImageFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && mode === "edit") {
      const formData: FormData = new FormData();
      formData.append("imgFile", e.target.files[0]);
      const addImageReq = await (
        await fetch(`/admin/add-image/${currentProduct.id}/${currentProduct.productType}`, {
          method: "POST",
          body: formData,
        })
      ).text();
      setCurrentProduct({ ...currentProduct, imgSrc: addImageReq });
    }
  };

  const addProduct = async () => {
    const addProductReq = await (
      await fetch("/admin/add-product", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(currentProduct),
      })
    ).text();
    displayAlertModal(addProductReq);
    if (addProductReq.includes("créé")) {
      setCurrentProduct(randomFoodProduct);
      setProductsListReload(productsListReload + 1);
    }
  };

  const modifyProduct = async () => {
    const modifyProductReq = await (
      await fetch("/admin/modify-product", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(currentProduct),
      })
    ).text();
    displayAlertModal(modifyProductReq);
    if (modifyProductReq.includes("modifié")) {
      setCurrentProduct(randomFoodProduct);
      setHandlerDisplay(false);
      setHandlerMode("add");
      setProductsListReload(productsListReload + 1);
    }
  };

  return (
    <form id="productHandler" className="ProductHandlerForm" style={{ display: display ? "flex" : "none" }}>
      <h2>{mode === "add" ? "Ajouter un produit" : "Modifier un produit"}</h2>

      <div
        className="imgContainer"
        style={{ backgroundImage: `url("${mode === "edit" ? "/get-image" + currentProduct.imgSrc : ""}")`, display: mode === "edit" ? "block" : "none" }}
      >
        <label htmlFor="imgInput">Modifier</label>
        <input id="imgInput" name="none" type="file" accept=".jpg, .jpeg, .png" onChange={addImageFile} />
      </div>

      <select
        value={currentProduct.productType}
        onChange={(e) => {
          setProductType(e);
        }}
      >
        <option value={ACCESSORY_STR}>Accessoire</option>
        <option value={FOOD_STR}>Nourriture</option>
        <option value={TREAT_STR}>Friandise</option>
      </select>

      <input
        name="text"
        type="text"
        placeholder="Titre"
        value={currentProduct.title}
        onChange={(e) => {
          setCurrentProduct({ ...currentProduct, title: e.target.value });
        }}
      />

      <input
        name="text"
        type="text"
        placeholder="Nom de la marque"
        value={currentProduct.brandName}
        onChange={(e) => {
          setCurrentProduct({ ...currentProduct, brandName: e.target.value });
        }}
      />

      <textarea
        placeholder="Description"
        value={currentProduct.description}
        onChange={(e) => {
          setCurrentProduct({ ...currentProduct, description: e.target.value });
        }}
      />

      <textarea
        placeholder="Description de la marque"
        value={currentProduct.brandDescription}
        onChange={(e) => {
          setCurrentProduct({ ...currentProduct, brandDescription: e.target.value });
        }}
      />

      <textarea
        placeholder="Ingrédients (en texte)"
        value={currentProduct.stringIngredients}
        onChange={(e) => {
          setCurrentProduct({ ...currentProduct, stringIngredients: e.target.value });
        }}
        style={{ display: currentProduct.productType === ProductType.Food ? "block" : "none" }}
      />

      <textarea
        placeholder="Valeurs nutritionnelles"
        value={currentProduct.nutritionalValues}
        onChange={(e) => {
          setCurrentProduct({ ...currentProduct, nutritionalValues: e.target.value });
        }}
        style={{ display: currentProduct.productType === ProductType.Food ? "block" : "none" }}
      />

      <input
        name="text"
        type="number"
        placeholder="Prix"
        value={currentProduct.price}
        onChange={(e) => {
          setCurrentProduct({ ...currentProduct, price: parseInt(e.target.value, 10) });
        }}
        style={{ display: currentProduct.productType !== ProductType.Food ? "block" : "none" }}
      />

      <input
        name="text"
        type="number"
        placeholder="Poids (en g)"
        value={currentProduct.weight}
        onChange={(e) => {
          setCurrentProduct({ ...currentProduct, weight: parseInt(e.target.value, 10) });
        }}
        style={{ display: currentProduct.productType !== ProductType.Food ? "block" : "none" }}
      />

      <input
        name="text"
        type="number"
        placeholder="Quantité disponible"
        value={currentProduct.stockQuantity}
        onChange={(e) => {
          setCurrentProduct({ ...currentProduct, stockQuantity: parseInt(e.target.value, 10) });
        }}
      />

      <select
        value={currentProduct.age}
        onChange={(e) => {
          setCurrentProduct({ ...currentProduct, age: stringToAgeEnum[e.target.value] });
        }}
      >
        <option value={ADULT_STR}>Adulte</option>
        <option value={YOUNG_STR}>Jeune</option>
        <option value={ALL_STR}>Tout</option>
      </select>

      <select
        value={currentProduct.animal}
        onChange={(e) => {
          setCurrentProduct({ ...currentProduct, animal: stringToAnimalEnum[e.target.value] });
        }}
      >
        <option value={DOG_STR}>Chien</option>
        <option value={CAT_STR}>Chat</option>
        <option value={ALL_STR}>Tout</option>
      </select>

      <select
        value={currentProduct.lineType}
        onChange={(e) => {
          setCurrentProduct({ ...currentProduct, lineType: stringToLineType[e.target.value] });
        }}
      >
        <option value={PREMIUM_STR}>Premium</option>
        <option value={SUPERPREMIUM_STR}>SuperPremium</option>
        <option value={ALL_STR}>Tout</option>
      </select>

      <select
        value={currentProduct.ingredients}
        style={{ display: currentProduct.productType === ProductType.Food ? "block" : "none" }}
        className="ingredientSelector"
        onChange={(e) => {
          setCurrentProduct({
            ...currentProduct,
            ingredients: Array.from(e.target.selectedOptions).map((i) => {
              return i.value;
            }) as Ingredient[],
          });
        }}
        multiple
        size={Object.keys(ingredientsList).length}
      >
        {(Object.keys(ingredientsList) as Ingredient[]).map((ingredient, index) => {
          return (
            <option key={index} value={ingredient}>
              {ingredientsList[ingredient] + (currentProduct.ingredients?.includes(ingredient) ? " *" : "")}
            </option>
          );
        })}
      </select>

      <div className="checkboxContainer" style={{ display: currentProduct.productType === ProductType.Food ? "flex" : "none" }}>
        <label>Dermasupport ?</label>
        <input
          type="checkbox"
          checked={currentProduct.hasDermaSupport ?? false}
          onChange={(e) => {
            setCurrentProduct({ ...currentProduct, hasDermaSupport: e.target.checked });
          }}
        />
      </div>

      <div className="dailyQuantitiesHandler" style={{ display: currentProduct.productType === ProductType.Food ? "block" : "none" }}>
        <h4>Quantités journalières</h4>
        <div className="dailyQuantity title">
          <p>Poids (kg)</p>
          <p>Quantité min. (gr)</p>
          <p>Quantité max. (gr)</p>
        </div>
        {currentProduct.dailyQuantities
          ? currentProduct.dailyQuantities
              .sort((a, b) => {
                return a.weight - b.weight;
              })
              .map((dq: DailyQuantity, index) => {
                return (
                  <div className="dailyQuantity" key={index}>
                    <p>{dq.weight} kg</p>
                    <p>{dq.range[0]} gr</p>
                    <p>{dq.range[1]} gr</p>
                    <button
                      className="dailyQuantityBtn"
                      onClick={(e) => {
                        e.preventDefault();
                        removeDailyQuantity(dq);
                      }}
                    >
                      x
                    </button>
                  </div>
                );
              })
          : ""}
        <div className="dailyQuantity add">
          <input
            name="none"
            type="number"
            value={dqWeight}
            placeholder="kg"
            onChange={(e) => {
              setDqWeight(parseInt(e.target.value));
            }}
          />
          <input
            name="none"
            type="number"
            value={dqRangeOne}
            placeholder="g"
            onChange={(e) => {
              setDqRangeOne(parseInt(e.target.value));
            }}
          />
          <input
            name="none"
            type="number"
            value={dqRangeTwo}
            placeholder="g"
            onChange={(e) => {
              setDqRangeTwo(parseInt(e.target.value));
            }}
          />
          <button
            className="dailyQuantityBtn"
            onClick={(e) => {
              e.preventDefault();
              addDailyQuantity();
            }}
          >
            +
          </button>
        </div>
      </div>

      <div className="dailyQuantitiesHandler" style={{ display: currentProduct.productType === ProductType.Food ? "block" : "none" }}>
        <h4>Poids et prix disponibles</h4>
        <div className="dailyQuantity title">
          <p>Poids (kg)</p>
          <p>Prix (€)</p>
          <p className="void"></p>
        </div>
        {currentProduct.availableLines
          ? currentProduct.availableLines
              .sort((a, b) => {
                return a.weight - b.weight;
              })
              .map((line: ProductLine, index) => {
                return (
                  <div className="dailyQuantity" key={index}>
                    <p>{line.weight} kg</p>
                    <p>{line.price} €</p>
                    <button
                      className="dailyQuantityBtn"
                      onClick={(e) => {
                        e.preventDefault();
                        removeProductLine(line);
                      }}
                    >
                      x
                    </button>
                  </div>
                );
              })
          : ""}
        <div className="dailyQuantity add">
          <input
            name="none"
            type="number"
            step=".01"
            value={productLineWeight}
            placeholder="Poids (kg)"
            onChange={(e) => {
              setProductLineWeight(parseFloat(e.target.value));
            }}
          />
          <input
            name="none"
            type="number"
            value={productLinePrice}
            placeholder="Prix (€)"
            onChange={(e) => {
              setProductLinePrice(parseInt(e.target.value));
            }}
          />
          <button
            className="dailyQuantityBtn"
            onClick={(e) => {
              e.preventDefault();
              addProductLine();
            }}
          >
            +
          </button>
        </div>
      </div>

      <button
        className="send"
        onClick={(e) => {
          e.preventDefault();
          mode === "add" ? addProduct() : modifyProduct();
        }}
      >
        Valider
      </button>
    </form>
  );
}
