import React from "react";

export default function Footer(){
    return(
        <footer>
            <h2>Contact</h2>
            <p>
                <img src={require("../images/inputs/phone-white.png")} alt="Téléphone : "/> 06 12 34 56 78<br/>
                <img src={require("../images/inputs/mail-white.png")} alt="E-mail : "/> contact@ruecanine.fr<br/><br/>
            </p>
            <h3>Rue Canine {(new Date()).getFullYear()} Tous droits réservés.</h3>
        </footer>
    )
}