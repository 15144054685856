import React, { useRef, useState } from "react";
import "./introVideo.scss"

export default function IntroVideo(){

    const [isPlaying, changeIsPlaying] = useState<boolean>(false)
    const [display, setDisplay] = useState<boolean>(true)

    const playerRef = useRef<HTMLVideoElement>(null)


    const playPause = ()=>{
        changeIsPlaying(!isPlaying)
        if(playerRef.current){
            if(isPlaying){
                playerRef.current.pause()
                setDisplay(true)
            }
            else{
                playerRef.current.play()
                setTimeout(()=>{
                    setDisplay(false)
                }, 1000)
            }
        }
    }

    return(
        <div id="IntroVideoContainer" onClick={playPause}>
            <div className="IntroVideoOverlay" style={{display: display ? "flex" : "none"}}>
                <button><img alt="" className={isPlaying ? "":"paused"} src={require("../images/video/"+(isPlaying ? "pause.png" : "play.png"))}/> </button>
            </div>
            <video id="IntroVideo" playsInline={true} poster={require("../images/video/klicker-pub-poster.png")} ref={playerRef} >
                <source src={require("../images/video/klicker-pub.mp4")} type="video/mp4"/>
            </video>
        </div>
    )
}