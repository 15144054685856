import React, { useState } from "react";
import { Age, Animal, Product, ProductLine, ProductType } from "../utils/generalTypes";
import "./adminProductItem.scss"
import { displayAlertModal } from "../utils/generalMethods";

export default function AdminProductItem({product, deleteProductHandler, displayModifyProductForm, productsListReload, setProductsListReload}:{
    product:Product, 
    deleteProductHandler:(product:Product)=>void, 
    displayModifyProductForm:(product:Product)=>void,
    setProductsListReload:React.Dispatch<React.SetStateAction<number>>,
    productsListReload:number
}){

    let productTypeText:string = "";
    switch(product.productType){
        case ProductType.Food:
            productTypeText = "Croquettes"
            break
        case ProductType.Treat:
            productTypeText = "Friandises"
            break
        case ProductType.Accessory:
            productTypeText = "Accessoire"
            break
        default:
            productTypeText = "Tout"
            break
    }

    const displayLongTextInModal = (textToDisplay:string)=>{
        displayAlertModal(textToDisplay)
    }

    let dailyQuantitiesString: string | undefined = undefined;
    let availableLinesString:string | undefined = undefined;

    if(product.productType === ProductType.Food){
        dailyQuantitiesString = ""
        availableLinesString = ""

        product.dailyQuantities?.forEach((dq, index)=>{
            dailyQuantitiesString += `${dq.weight}kg (${dq.range[0] ?? ""}g - ${dq.range[1] ?? ""}g) ${index === product.dailyQuantities?.length? - 1 ? "  -  " : "" : ""}`
        })

        product.availableLines?.forEach((l, index)=>{
            availableLinesString += `${l.weight}kg (${l.price}€) ${index === product.dailyQuantities?.length? - 1 ? " - " : "" : ""}`
        })
    }


    const addImageFile = async (e:React.ChangeEvent<HTMLInputElement>)=>{
        if(e.target.files){
            const formData:FormData = new FormData()
            formData.append("imgFile", e.target.files[0])
            await(
                await fetch(`/admin/add-image/${product.id}/${product.productType}`, {
                    method:"POST",
                    body:formData
                })
            ).text()
            setProductsListReload(productsListReload + 1)
        }
    }

    return(
        <div className="apProductItem">
            <div className="imgContainer"  style={{backgroundImage:`url("/get-image${product.imgSrc}")`}}>
                <label htmlFor="imgInput2">Modifier</label>
                <input id="imgInput2" name="none" type="file" accept=".jpg, .jpeg, .png" onChange={addImageFile} onClick={(e)=>{e.currentTarget.value=""}} />
            </div>

            <h3 className="apProductItemTitle">{product.title}</h3>

            <div className="row longText" onClick={()=>{displayLongTextInModal(product.description)}}>
                <h4>Description</h4>
                <p className="apProductItemKeyLabel">{product.description}</p>
            </div>
            
            <div className="row longText" onClick={()=>{displayLongTextInModal(product.brandDescription)}}>
                <h4>Description du fabriquant</h4>
                <p className="apProductItemKeyLabel">{product.brandDescription}</p>
            </div>
            
            <div className="row">
                <h4>Type de produit</h4>
                <p className="apProductItemKeyLabel">{productTypeText}</p>
            </div>

            <div className="row" style={{display: product.productType === ProductType.Food ? "flex":"none"}}>
                <h4>Dermasupport ? </h4>
                <p className="apProductItemKeyLabel">{product.hasDermaSupport ? "Oui" : "Non"}</p>
            </div>
            
            <div className="row">
                <h4>Âge</h4>
                <p className="apProductItemKeyLabel">{product.age === Age.Adult ? "Adulte" : product.age === Age.Young ? "Jeune" : "Tout"}</p>
            </div>
            
            <div className="row">
                <h4>Disponible ?</h4>
                <p className="apProductItemKeyLabel">{product.stockAvailable ? "Oui" : "Non"}</p>
            </div>
            
            <div className="row">
                <h4>Quantité disponible</h4>
                <p className="apProductItemKeyLabel">{product.stockQuantity}</p>
            </div>
            
            <div className="row">
                <h4>Animal</h4>
                <p className="apProductItemKeyLabel">{product.animal === Animal.Dog ? "Chien" : product.animal === Animal.Cat ? "Chat" : "Tout"}</p>
            </div>
            
            <div className="row">
                <h4>Fabriquant</h4>
                <p className="apProductItemKeyLabel">{product.brandName}</p>
            </div>
            
            <div className="row">
                <h4>Gamme</h4>
                <p className="apProductItemKeyLabel">{product.lineType}</p>
            </div>

            <div className="row" style={{display: product.productType !== ProductType.Food ? "flex":"none"}}>
                <h4>Prix (en €)</h4>
                <p className="apProductItemKeyLabel">{product.price}</p>
            </div>

            <div className="row" style={{display: product.productType !== ProductType.Food ? "flex":"none"}}>
                <h4>Poids (en {product.weight && product.weight > 1000 ? "kg" : "g"})</h4>
                <p className="apProductItemKeyLabel">{product.weight ? product.weight > 1000 ? product.weight / 1000 : product.weight : 0}</p>
            </div>

            <div className="row" style={{display: product.productType === ProductType.Food ? "flex":"none"}}>
                <h4>Poids disponibles</h4>
                <p className="apProductItemKeyLabel">{availableLinesString}</p>
            </div>
            
            <div className="row longText" style={{display: product.productType === ProductType.Food ? "flex":"none"}}  onClick={()=>{displayLongTextInModal(product.stringIngredients ?? "")}}>
                <h4>Ingrédients (en texte)</h4>
                <p className="apProductItemKeyLabel">{product.stringIngredients}</p>
            </div>

            <div className="row longText" style={{display: product.productType === ProductType.Food ? "flex":"none"}}  onClick={()=>{displayLongTextInModal(product.nutritionalValues ?? "")}}>
                <h4>Valeurs nutritionnelles</h4>
                <p className="apProductItemKeyLabel">{product.nutritionalValues}</p>
            </div>

            <div className="row longText" style={{display: product.productType === ProductType.Food ? "flex":"none"}} onClick={()=>{displayLongTextInModal(product.ingredients ? product.ingredients.join(" - ") : "")}}>
                <h4>Ingrédients</h4>
                <p className="apProductItemKeyLabel">{product.ingredients ? product.ingredients.join(" - ") : ""}</p>
            </div>

            <div className="row longText" style={{display: product.productType === ProductType.Food ? "flex":"none"}} onClick={()=>{displayLongTextInModal(dailyQuantitiesString ?? "")}}>
                <h4>Quantités journalière</h4>
                <p className="apProductItemKeyLabel">{dailyQuantitiesString ?? ""}</p>
            </div>

            <div className="row buttonsContainer">
                <button className="send" onClick={()=>{deleteProductHandler(product)}}>Supprimer</button>
                <button className="send" onClick={()=>{displayModifyProductForm(product)}}>Modifier</button>
            </div>

        </div>
    )
}