import React, { useEffect, useState } from 'react';
import './App.scss';
import GeneralPage from './generalPage';
import SplashScreen from './splashScreen';
import { IGeneralWebsiteData, UserDataServer } from '../utils/generalTypes';
import { setGeneralDataLocal, setLoggedin, setUserData } from '../utils/generalMethods';


function App() {

  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(()=>{
    const getGeneralData = async ()=>{
      let websiteGeneralDataReq:string = await (await fetch("/general-website-data")).text()
      if(websiteGeneralDataReq.includes("{")){
        const generalWebsiteData:IGeneralWebsiteData = JSON.parse(websiteGeneralDataReq)
        setGeneralDataLocal(generalWebsiteData)
      }
      setTimeout(()=>{
        setIsLoaded(true)
      }, 1000)
    }

    const checkLogin = async ()=>{
      const loginCheckReq = await(
        await fetch("/user/check-login")
      ).text()
      if(loginCheckReq.includes("{")){
        const newUserData:UserDataServer = JSON.parse(loginCheckReq)
        setUserData(newUserData)
        setLoggedin(true)
      }
    }

    getGeneralData()
    checkLogin()
  }, [])



  return (
    <div className="App">
        {isLoaded ? <GeneralPage/> : <SplashScreen/>}
    </div>
  );
}

export default App;
