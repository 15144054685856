import React, { useEffect, useState } from "react";
import { Age, Animal, LineType } from "../utils/generalTypes";
import { Link } from "react-router-dom";
import "./foodSelector.scss"

export default function FoodSelector({lpAnimalChoice}:{lpAnimalChoice:Animal}){


    const [animal, setAnimal] = useState<Animal>(lpAnimalChoice)
    const [age, setAge] = useState<Age>(Age.Adult)
    const [productLine, setProductLine] = useState<LineType>(LineType.Premium)

    useEffect(()=>{
        if(animal !== lpAnimalChoice){
            setAnimal(lpAnimalChoice)
        }
    }, [lpAnimalChoice])


    return(
        <div id="FoodSelector">
            <h1>Trouvez ci-dessous en quelque clics les croquettes qui correspondent à votre animal.</h1>

            <h2>Animal</h2>

            <div className="choiceContainer">
                <label>
                    <input type="radio" name="animal" checked={animal === Animal.Dog ? true : false} onChange={(e)=>{setAnimal(e.target.checked ? Animal.Dog : Animal.Cat)}}/>
                    <div className="foodSelectorChoice">
                        <img src={require("../images/quizz/dog-choice.png")} alt=""/>
                        <p>Chien</p>
                    </div>
                </label>

                <label>
                    <input type="radio" name="animal" checked={animal === Animal.Cat ? true : false} onChange={(e)=>{setAnimal(e.target.checked ? Animal.Cat : Animal.Dog)}} />
                    <div className="foodSelectorChoice">
                        <img src={require("../images/quizz/cat-choice.png")} alt=""/>
                        <p>Chat</p>
                    </div>
                </label>
            </div>            
            
            
            
            <h2>Âge</h2>

            <div className="choiceContainer">
                <label>
                    <input type="radio" name="age" checked={age === Age.Adult ? true : false} onChange={(e)=>{setAge(e.target.checked ? Age.Adult : Age.Young)}}/>
                    <div className="foodSelectorChoice">
                        <img src={
                            require(`../images/quizz/${animal === Animal.Dog ? "dog" : "cat"}.png`)
                        } alt=""/>
                        <p>{animal === Animal.Dog ? "Chien" : "Chat"}</p>
                    </div>
                </label>

                <label>
                    <input type="radio" name="age" checked={age === Age.Young ? true : false}  onChange={(e)=>{setAge(e.target.checked ? Age.Young : Age.Adult)}} />
                    <div className="foodSelectorChoice">
                        <img src={
                            require(`../images/quizz/${animal === Animal.Dog ? "puppy" : "kitten"}.png`)
                        } alt=""/>
                        <p>{animal === Animal.Dog ? "Chiot" : "Chaton"}</p>
                    </div>
                </label>
            </div>

            
            
            <h2>Gamme</h2>

            <div className="choiceContainer">
                <label>
                    <input type="radio" name="productline" checked={productLine === LineType.Premium ? true : false}  onChange={(e)=>{setProductLine(e.target.checked ? LineType.Premium : LineType.SuperPremium)}} />
                    <div className="foodSelectorChoice">
                        <img src={require("../images/quizz/premium-quality.png")} alt=""/>
                        <p>Premium</p>
                    </div>
                </label>

                <label>
                    <input type="radio" name="productline" checked={productLine === LineType.SuperPremium ? true : false} onChange={(e)=>{setProductLine(e.target.checked ? LineType.SuperPremium : LineType.Premium)}}/>
                    <div className="foodSelectorChoice">
                        <img src={require("../images/quizz/premium-badge.png")} alt=""/>
                        <p>Super Premium</p>
                    </div>
                </label>
            </div>
            

            <Link to={`/products/get-products/all/${animal}/${age}/${productLine}`} className="send">Voir mes croquettes</Link>

            
        </div>
    )
}