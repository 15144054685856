import React, { useEffect, useState } from "react";
import "./paymentPage.scss";
import { Animal, BuyingType, CartItem, IPromoCode, Order, ProductType } from "../utils/generalTypes";
import MondialRelay from "../components/mondialRelayWidget";
import { useAppSelector } from "../reduxFolder/store";
import { Link, useNavigate } from "react-router-dom";
import { CardElement, Elements, useElements } from "@stripe/react-stripe-js";
import { PaymentIntent, Stripe, loadStripe } from "@stripe/stripe-js";
import { latLng } from "leaflet";
import { isProd } from "..";
import Summary from "../components/productSummary";
import { displayAlertModal, setCartItemsLocal, setUserData } from "../utils/generalMethods";

interface ICoordinates {
  lat: number;
  lon: number;
}

function Payment({ stripe, coordinates }: { stripe: Stripe; coordinates: ICoordinates[] }) {
  const state = useAppSelector((state) => state.general);
  const elements = useElements();
  const navigate = useNavigate();
  const generalDeliveryFee = useAppSelector((state) => state.general.generalWebsiteData.deliveryFee);

  const pay = async () => {
    setButtonLabel("En cours...");

    const stripeCardElement = elements?.getElement(CardElement);

    if (stripeCardElement) {
      const stripePaymentMethod = await stripe.createPaymentMethod({
        type: "card",
        card: stripeCardElement,
      });
      const stripeCardId: string | undefined = stripePaymentMethod && stripePaymentMethod.paymentMethod ? stripePaymentMethod?.paymentMethod.id : undefined;
      if (stripeCardId) {
        const order: Order = {
          products: state.cart,
          orderType: /* isSubscription ? BuyingType.subscription :  */ BuyingType.simpleBuy,
          /* frequency,
                    onGoing:true, */
          date: new Date().toLocaleDateString(),
          totalPrice,
          paymentInformations: {
            address,
            name,
            promoCode,
            relayId,
            stripeCardId,
          },
        };

        if (!homeDelivery && order.paymentInformations) {
          order.paymentInformations.relayId = relayId;
        }

        const payReq = await (
          await fetch("/payment/pay", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ order }),
          })
        ).text();

        if (payReq.includes("{")) {
          let serverResponse: PaymentIntent = JSON.parse(payReq);
          if (serverResponse.status === "requires_action") {
            while (serverResponse.status === "requires_action" && serverResponse.client_secret) {
              await stripe.handleNextAction({ clientSecret: serverResponse.client_secret });
            }
          }
          if (serverResponse.status === "requires_confirmation" && serverResponse.client_secret != null) {
            const { error, paymentIntent } = await stripe.confirmPayment({
              clientSecret: serverResponse.client_secret,
              confirmParams: {
                return_url: (isProd ? "https://ruecanine.fr" : "http://localhost:8080") + "/payment-done",
              },
              redirect: "if_required",
            });

            if (paymentIntent && (paymentIntent.status === "succeeded" || paymentIntent.status === "processing")) {
              await fetch("/payment/payment-done", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  order,
                  paymentIntentId: paymentIntent.id,
                }),
              });
              const previousOrdersReq = await (await fetch("/user/get-previous-orders")).json();
              setUserData({ ...state.userData, previousOrders: previousOrdersReq });

              setCartItemsLocal([]);
              navigate("/payment-done");
            } else {
              console.error(paymentIntent?.status);
              navigate("/");
              displayAlertModal("La vérification de votre carte a échoué.");
            }
          } else if (serverResponse.status === "succeeded" || serverResponse.status === "processing") {
            const previousOrdersReq = await (await fetch("/user/get-previous-orders")).json();
            setUserData({ ...state.userData, previousOrders: previousOrdersReq });
            setCartItemsLocal([]);
            navigate("/payment-done");
          } else {
            displayAlertModal("Le paiement a échoué. Votre carte est invalide ou bien votre solde est insuffisant.");
            navigate("/");
          }
        } else {
          displayAlertModal(payReq);
          navigate("/");
        }
      }
    }
  };

  const handlePromoCode = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setPromoCode(e.target.value);
    if (e.target.value.length === 8) {
      const promoCodeReq = await (await fetch("/payment/promo-code/" + e.target.value)).text();
      if (promoCodeReq.includes("{")) {
        const promoCodeServer: IPromoCode = JSON.parse(promoCodeReq);
        setPromoAmount(0.01 * promoCodeServer.percent * totalPrice);
        setPromoPercent(promoCodeServer.percent);
      } else {
        displayAlertModal(promoCodeReq);
        setPromoCode("");
        setPromoAmount(undefined);
      }
    }
  };

  const calculateTotalPrice = () => {
    let totalPrice: number = 0;
    if (state.cart && state.cart.length) {
      let quantityOfFood: number = 0;

      state.cart.forEach((cartItem) => {
        totalPrice += cartItem.quantity * (cartItem.product.availableLines?.[cartItem.selectedLineIndex ?? 0].price ?? cartItem.product.price ?? 0);
        if (cartItem.product.productType === ProductType.Food && cartItem.product.animal === Animal.Dog) {
          quantityOfFood += cartItem.quantity;
        }
      });
      let tmpDeliveryFees: number = 0;
      if (quantityOfFood > 0) {
        tmpDeliveryFees = homeDelivery ? (quantityOfFood > 1 ? 0 : generalDeliveryFee) : quantityOfFood % 2 === 0 ? 0 : generalDeliveryFee;
      } else tmpDeliveryFees = totalPrice >= 50 ? 0 : generalDeliveryFee;

      totalPrice = totalPrice + tmpDeliveryFees;
      if (promoPercent) {
        setPromoAmount(Math.round(0.01 * promoPercent * totalPrice * 100) / 100);
        totalPrice = (1 - 0.01 * promoPercent) * totalPrice;
      }

      setDeliveryFees(tmpDeliveryFees);
      setTotalPrice(totalPrice);
    }
  };

  const getQuantityOfDogFood = async (cart: CartItem[]): Promise<number> => {
    let totalQuantity = 0;
    cart.forEach((cartItem) => {
      if (cartItem.product.animal === Animal.Dog && cartItem.product.productType === ProductType.Food) {
        totalQuantity += cartItem.quantity;
      }
    });
    return totalQuantity;
  };

  const [isSubscription, setIsSubscription] = useState<boolean>(false);
  const [frequency, setFrequency] = useState<number>(1);
  const [relayId, setRelayId] = useState("");

  const [name, setName] = useState<string>(state.userData.name ?? "");
  const [address, setAddress] = useState<string>(state.userData.address ?? "");
  const [promoCode, setPromoCode] = useState<string>("");
  const [deliveryFees, setDeliveryFees] = useState<number>(generalDeliveryFee ?? 0);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [isHomeDeliveryPossible, setIsHomeDeliveryPossible] = useState<boolean>(false);
  const [homeDelivery, setHomeDelivery] = useState<boolean>(false);
  const [promoAmount, setPromoAmount] = useState<number | undefined>(undefined);
  const [promoPercent, setPromoPercent] = useState<number | undefined>(undefined);
  const [buttonLabel, setButtonLabel] = useState("Finaliser");

  useEffect(() => {
    const isDeliveryPossible = async () => {
      if (coordinates && coordinates.length === 2) {
        const point1 = latLng(coordinates[0].lat, coordinates[0].lon);
        const point2 = latLng(coordinates[1].lat, coordinates[1].lon);
        const quantityOfDogFood = await getQuantityOfDogFood(state.cart);
        if (point1.distanceTo(point2) / 1000 < 25 && quantityOfDogFood >= 2) {
          setIsHomeDeliveryPossible(true);
          setHomeDelivery(true);
          setDeliveryFees(0);
        }
      }
    };

    isDeliveryPossible();
  }, []);

  useEffect(calculateTotalPrice, [homeDelivery, promoCode, promoPercent]);

  return (
    <div id="PaymentPage">
      <h2>Paiement</h2>
      <h3>C'est presque terminé !</h3>
      <div className="summary">
        <h4>Récapitulatif de votre commande</h4>
        <Summary productList={state.cart} />
      </div>

      {/* <div className="row buyingType">
                <p>Prendre un abonnement</p>
                <input type="checkbox" checked={isSubscription} onChange={(e)=>{setIsSubscription(e.target.checked)}} />
            </div>
            <div className="row frequencyContainer" style={{display: isSubscription ? "flex" : "none"}}>
                <p>Fréquence en mois</p>
                <select onChange={(e)=>{setFrequency(parseInt(e.target.value, 10))}}>
                    {
                        [1,2,4,6,8].map((i, index)=>{
                            return(
                                <option key={index} value={i}>{i}</option>
                            )
                        })
                    }
                </select>
            </div> 
 */}

      <form>
        <input
          name="name"
          type="text"
          placeholder="Nom du titulaire"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
        <input
          name="adress"
          type="text"
          placeholder="Adresse de facturation"
          value={address}
          onChange={(e) => {
            setAddress(e.target.value);
          }}
        />
        <CardElement
          options={{
            style: {
              base: {
                backgroundColor: "transparent",
                color: "black",
                "::placeholder": { color: "gray" },
                fontSize: "18px",
                lineHeight: "32px",
              },
              invalid: {
                color: "darkred",
              },
            },
            classes: {
              base: "StripeElement",
            },
          }}
        />
        <input name="promotioncode" type="text" placeholder="Code de promotion" value={promoCode} onChange={(e) => handlePromoCode(e)} />

        <div className="livraison">
          <p style={{ display: isHomeDeliveryPossible ? "block" : "none" }}>
            Votre adresse est éligible à la livraison à domicile. Nous nous occupons personellement de la livraison chez vous. Si vous choisissez la livraison à
            domicile, nous vous recontacterons dans les plus brefs délais.
            <br />
            Vous pouvez également décocher la case ci-dessous et choisir une livraison par MondialRelay
          </p>

          <label style={{ display: isHomeDeliveryPossible ? "block" : "none" }}>
            Je souhaite me faire livrer à domicile.
            <input
              type="checkbox"
              checked={homeDelivery}
              onChange={(e) => {
                setHomeDelivery(e.target.checked);
              }}
            />
          </label>

          <MondialRelay setRelayId={setRelayId} isVisible={homeDelivery} />
        </div>

        <p className="paymentFormBoldText">
          Les frais de livraison sont offerts en cas de nombre pair (deux par deux) de croquettes pour chien, ou si la commande ne contient pas de croquettes
          pour chien, à partir de 50€.
        </p>

        <div className="deliveryFees row">
          <p>Frais de livraison</p>
          <p>{deliveryFees} €</p>
        </div>

        <div className="deliveryFees row" style={{ display: promoAmount ? "flex" : "none" }}>
          <p>Réduction</p>
          <p> - {promoAmount ?? ""} €</p>
        </div>

        <div className="totalPrice row">
          <p>Prix total</p>
          <p>{totalPrice} €</p>
        </div>

        <p className="paymentFormBoldText">
          En finalisant la commande, vous reconnaissez avoir lu et accepté les{" "}
          <Link className="link" to="/cgv">
            Mentions légales et CGV
          </Link>{" "}
          ainsi que la charte{" "}
          <Link className="link" to="/privacy">
            Vie Privée
          </Link>{" "}
        </p>

        <button
          className="send"
          onClick={(e) => {
            e.preventDefault();
            pay();
          }}
        >
          {buttonLabel}
        </button>
      </form>
    </div>
  );
}

export default function PaymentPage() {
  const state = useAppSelector((state) => state.general);
  const navigate = useNavigate();
  const [displayPage, setDisplayPage] = useState<boolean>(false);
  const [stripePromise, setStripePromise] = useState<Stripe | null>();
  const [markers, setMarkers] = useState<ICoordinates[]>([]);

  useEffect(() => {
    if (!(state.cart && state.cart.length)) {
      // Don't display page and go to landing page if cart is empty
      navigate("/");
    } else {
      const init = async () => {
        const stripe: Stripe | null = await loadStripe(
          isProd
            ? "pk_live_51Os9zHAW0IvoWxVRSv5d3vRpQdXCbEHKkxQaBi7WH7mCShMmSZu8Ox2QaLCjsi0XiTYg06F5oRG7ZrPs8fjkxo2200xXWEhDmt"
            : "pk_test_51Os9zHAW0IvoWxVR2Vs0j2xVv270ZVr93UQ6Ov6qzIQBdfkWcDE1uv2PcRQFkr28Fj6Ueeq1MtcQmga4QfHFhSZv00YTuBauB6"
        );

        const data: any = await (
          await fetch(
            (isProd ? "https" : "http") +
              "://nominatim.openstreetmap.org/search?format=json&q=" +
              encodeURI(`${state.userData.address}, ${state.userData.postalCode} ${state.userData.city}`)
          )
        ).json();

        if (data && data.length) {
          setMarkers([
            {
              lat: 49.044983,
              lon: 2.322579,
            },
            {
              lat: data[0].lat ?? 0,
              lon: data[0].lon ?? 0,
            },
          ]);
        }

        if (stripe) {
          setStripePromise(stripe);
          setDisplayPage(true);
        } else {
          navigate("/");
        }
      };
      init();
    }
  }, []);

  if (displayPage && stripePromise) {
    return (
      <Elements stripe={stripePromise}>
        <Payment stripe={stripePromise} coordinates={markers} />
      </Elements>
    );
  } else return <p></p>;
}
