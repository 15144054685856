import React, { useEffect, useRef,  } from 'react';
import "./mondialRelayWidget.scss"
import { isProd } from '..';


export default function MondialRelay({setRelayId, isVisible}){


    useEffect(() => {
        window.$("#MRWidget").MR_ParcelShopPicker({  
            Target: "#parcelShopCode",
            Brand: isProd ? "CC22Q3NY" : "BDTEST  ",
            Country: "FR",
            ShowResultsOnMap:false, 
            Responsive:true,
            Weight:30000,
            ColLivMod:"24R",
            OnParcelShopSelected:(data)=>{
                setRelayId(data.ID)
            }
        });  
    }, []);  


    return(
        <div id="Deliver" style={{display: isVisible === false ? "block":"none"}}>
            <div id="MRWidget" ></div>
        </div>

    )
};
