import React, { useState } from "react";
import {BuyingType, UserDataServer } from "../utils/generalTypes";
import "./account.scss"
import "./paymentPage.scss"
import { useAppSelector } from "../reduxFolder/store";
import { useNavigate } from "react-router-dom";
import Summary from "../components/productSummary";
import { displayAlertModal, setCartItemsLocal, setLoggedin } from "../utils/generalMethods";

export default function Account(){


    let userData:UserDataServer = useAppSelector(state=>state.general.userData)
    const navigate = useNavigate()

    const [tel, setTel] = useState<string>(userData.tel)
    const [address, setAddress] = useState<string>(userData.address)
    const [postalCode, setPostalCode] = useState<string>(userData.postalCode)
    const [city, setCity] = useState<string>(userData.city)
    const [password, setPassword] = useState<string>("")
    const [confirmPassword, setConfirmPassword] = useState<string>("")
    const [promoEmail, setPromoEmail] = useState<string>("")
    const [promoName, setPromoName] = useState<string>("")

    const [displayInfosChanger, setDisplayInfosChanger] = useState<boolean>(false)
    const [displayPromoInput, setDisplayPromoInput] = useState<boolean>(false)

    const changeInformations = async ()=>{
        const changeInfosReq = await(
            await fetch("/user/modify-user-informations", {
                method:"POST",
                headers:{
                    "Content-Type":"application/json"
                },
                body:JSON.stringify({
                    tel,
                    address,
                    postalCode,
                    city,
                    password,
                    confirmPassword
                })
            })
        ).text()
        if(changeInfosReq.includes("reconnecter")){
            setLoggedin(false)
            navigate("/")
            displayAlertModal(changeInfosReq)
        }
        
    }

    const sendPromoCodeToEmail = async ()=>{
        const sendPromoCodeReq = await (
            await fetch("/user/create-sponsor-code", {
                method:"POST",
                headers:{
                    "Content-Type":"application/json"
                },
                body:JSON.stringify({
                    recipientName:promoName,
                    recipientEmail:promoEmail
                })
            })
        ).text()
        displayAlertModal(sendPromoCodeReq)
    }

    const logout = async ()=>{
        await fetch("/user/logout")
        setLoggedin(false)
        setCartItemsLocal([])
        navigate("/")
    }

    console.error(userData.previousOrders)

    return(
        <div className="account">
            <h2>Votre compte</h2>
            <div className="userInformations">
                <div className="row">
                    <h3>Nom</h3>
                    <p>{userData.name}</p>
                </div>
                <div className="row">
                    <h3>E-mail</h3>
                    <p>{userData.email}</p>
                </div>
                <div className="row">
                    <h3>Téléphone</h3>
                    <p>{userData.tel}</p>
                </div>
                <div className="row">
                    <h3>Adresse</h3>
                    <p>{userData.address}</p>
                </div>
            </div>

            <input name="email" type="text" placeholder="E-mail de votre proche" style={{display: displayPromoInput ? "block" : "none"}} value={promoEmail} onChange={(e)=>{setPromoEmail(e.target.value)}}/>
            <input name="email" type="text" placeholder="Nom de votre proche" style={{display: displayPromoInput ? "block" : "none"}} value={promoName} onChange={(e)=>{setPromoName(e.target.value)}}/>

            <button className="send" onClick={()=>{displayPromoInput ? sendPromoCodeToEmail() : setDisplayPromoInput(true)}}>
                {
                    displayPromoInput ? "Envoyer" :  "Envoyer un code de parrainage" 
                }
            </button>

            <form className="modifyInformations" style={{display:displayInfosChanger ? "block":"none"}}>
                <input name="tel" type="text" placeholder="Téléphone" value={tel} onChange={(e)=>{setTel(e.target.value)}}/>
                <input name="adress" type="text" placeholder="N° rue, voie.." value={address} onChange={(e)=>{setAddress(e.target.value)}}/>
                <input name="adress" type="text" placeholder="Code postal" value={postalCode} onChange={(e)=>{setPostalCode(e.target.value)}}/>
                    <input name="adress" type="text" placeholder="Ville" value={city} onChange={(e)=>{setCity(e.target.value)}}/>
                <input name="password" type="password" placeholder="Mot de passe" value={password} onChange={(e)=>{setPassword(e.target.value)}}/>
                <input name="password" type="password" placeholder="Confirmer le mot de passe" value={confirmPassword} onChange={(e)=>{setConfirmPassword(e.target.value)}}/>
            </form><br/>

            

            <button className="send" onClick={()=>{displayInfosChanger ? changeInformations() : setDisplayInfosChanger(true)}}>
                {
                    displayInfosChanger ? "Valider" :  "Modifier vos informations" 
                }
            </button><br/>
            <button className="send" onClick={logout}>Déconnexion</button>

            <div className="allOrders" style={{display: userData.previousOrders ? "block":"none"}}>
                <h2>Vos commandes</h2>
                {
                    userData.previousOrders ? userData.previousOrders.slice().reverse().map((order, index)=>{
                        return (
                            <div className="summary" key={index}>
                                <h4>Commande du {order.date}</h4>
                                {
                                    order.products ? <Summary productList={order.products} /> : ""
                                }
                                <button style={{display: order.orderType === BuyingType.subscription && order.onGoing === true ? "block" : "none"}} className="send">Annuler votre abonnement</button>
                            </div>
                        ) 
                    }) : ""
                }
            </div>
        
        </div>
    )
}