import React, { useEffect, useState } from "react";
import {Age, Animal, LineType, Product, ProductType} from "../utils/generalTypes";
import { displayAlertModal, displayConfirmModal } from "../utils/generalMethods";
import AdminProductItem from "./adminProductItem";
import AdminProductHandler, { mode } from "./adminProductHandler";
import "./adminProducts.scss"
import { randomFoodProduct } from "../utils/generalData";



export default function AdminProducts(){

    const [search, setSearch] = useState<string>("")
    const [productsListReload, setProductsListReload] = useState<number>(0)
    const [products, setProducts] = useState<Product[]>([])
    const [handlerMode, setHandlerMode] = useState<mode>("add")
    const [handlerProduct, setHandlerProduct] = useState<Product>(randomFoodProduct)
    const [handlerDisplay, setHandlerDisplay] = useState<boolean>(false)

    const deleteProduct = async (productId:string)=>{
        const deleteProductReq = await(
            await fetch("/admin/delete-product", {
                method:"POST",
                headers:{
                    "Content-Type":"application/json"
                },
                body:JSON.stringify({
                    productId
                })
            })
        ).text()
        displayAlertModal(deleteProductReq)
        setProductsListReload(productsListReload + 1)
    }

    const displayAddProductForm = ()=>{
        setHandlerMode("add")
        setHandlerDisplay(true)
    }

    const displayModifyProductForm = (product:Product)=>{
        setHandlerMode("edit")
        setHandlerProduct(product)
        setHandlerDisplay(true)
        window.location.href = window.location.href.replace("#productHandler", "") + "#productHandler"
        window.scrollTo({top:0, behavior:"smooth"})
    }

    const deleteProductHandler = (product:Product)=>{
        displayConfirmModal("Êtes-vous sûr de vouloir supprimer ce produit ?", async ()=>{deleteProduct(product.id)})
    }

    useEffect(()=>{
        const getAllProducts = async()=>{
            const getProductsReq = await(
                await fetch("/admin/all-products")
            ).text()
            if(getProductsReq.includes("{")){
                setProducts(JSON.parse(getProductsReq))
            }
            else{
                displayAlertModal(getProductsReq)
            }
        }
        getAllProducts()
    }, [productsListReload])


    let filteredProducts:Product[] = [...products].reverse()
    search !== "" ? filteredProducts = filteredProducts.filter(product=>product.title.toLowerCase().includes(search.toLowerCase())) : filteredProducts.length = 20
    return(
        <div className="apProducts">
            <h2>Produits</h2>

            <button className="send" onClick={displayAddProductForm}>Ajouter un produit</button> <br/>

            <AdminProductHandler 
                key={JSON.stringify(handlerProduct)} 
                display={handlerDisplay} 
                setHandlerDisplay={setHandlerDisplay} 
                setHandlerMode={setHandlerMode} 
                mode={handlerMode} 
                product={handlerProduct} 
                setProductsListReload={setProductsListReload}
                productsListReload={productsListReload}
            />

            <input type="text" name="search" placeholder="Rechercher un produit par titre" value={search} onChange={(e)=>{setSearch(e.target.value)}} />
            {
                filteredProducts.length ? 
                    filteredProducts.map((product, index)=>{
                        return(
                            <AdminProductItem 
                                displayModifyProductForm={displayModifyProductForm} 
                                product={product} key={index} 
                                deleteProductHandler={deleteProductHandler}
                                setProductsListReload={setProductsListReload}
                                productsListReload={productsListReload}
                            />
                        )
                    })
                : <p>Aucun produit ne correspond à votre recherche</p>
            }
        </div>
    )
}