import React, { useEffect, useState } from "react";
import { IPromoCode } from "../utils/generalTypes";
import "./adminPromos.scss"
import {displayAlertModal, displayConfirmModal } from "../utils/generalMethods";

export default function AdminPromos(){

    const [promoCodes, setPromoCodes] = useState<IPromoCode[]>([])
    const [promoSearch, setPromoSearch] = useState<string>("")
    const [displayForm, setDisplayForm] = useState<boolean>(false)
    const [promoPercent, setPromoPercent] = useState<number>(0)
    const [promoTargetEmail, setPromoTargetEmail] = useState<string>("")
    const [promoListReload, setPromoListReload] = useState<number>(0)
    const [isGlobal, setIsGlobal] = useState<boolean>(false)
    const [isModifyFormVisible, setIsModifyFormVisible] = useState<boolean>(false)

    const [currentPromo, setCurrentPromo] = useState<IPromoCode>({
        code:"",
        isGlobal:false,
        isValid:false,
        percent:0
    }) // False object to avoid type issues


    const promoInfosLabel = ["Code", "Pourcentage de remise", "Valide", "Réutilisable", "E-mail du parrain", "Nom du proche", "E-mail du proche"]

    useEffect(()=>{
        const getPromos = async () =>{
            const promosReq = await (
                await fetch("/admin/all-promos")
            ).text()
            if(promosReq.includes("{")){
                setPromoCodes(JSON.parse(promosReq))
            }
            else{
                displayAlertModal(promosReq)
            }
        }
        getPromos()
    }, [promoListReload])

    let filteredPromos:IPromoCode[] = [...promoCodes].reverse()
    promoSearch !== "" ? filteredPromos = filteredPromos.filter(promo=> [promo.code, promo.sponsorship?.fromEmail, promo.sponsorship?.toEmail, promo.sponsorship?.toName].join("").toLowerCase().includes(promoSearch.toLowerCase())) : filteredPromos.length = 30

    const createPromoCode = async ()=>{
        if(displayForm){
            const createPromoReq = await(
                await fetch("/admin/create-promo-code", {
                    method:"POST",
                    headers:{
                        "Content-Type":"application/json"
                    },
                    body:JSON.stringify({
                        percent: promoPercent,
                        targetEmail: promoTargetEmail,
                        isGlobal
                    })
                })
            ).text()
            if(createPromoReq.includes("créée")){
                setDisplayForm(false)
                setPromoPercent(0)
                setPromoTargetEmail("")
                setIsGlobal(false)
                setPromoListReload(promoListReload + 1)
            }   
            displayAlertModal(createPromoReq)
        }
        else{
            setDisplayForm(true)
        }
    }

    const deletePromoCode = async (code:string)=>{

        displayConfirmModal("Êtes-vous sûr de vouloir supprimer la promotion ?", async ()=>{confirmDelete(code)})

    }

    const confirmDelete = async (code:string)=>{
        const deletePromoReq = await(
            await fetch("/admin/delete-promo-code", {
                method:"POST",
                headers:{
                    "Content-Type":"application/json"
                },
                body:JSON.stringify({code})
            })
        ).text()
        displayAlertModal(deletePromoReq)
        setPromoListReload(promoListReload + 1)
    }

    const goToModifyForm = (promo:IPromoCode)=>{
        setCurrentPromo(promo)
        setIsModifyFormVisible(true)
        window.location.href = window.location.href.replace("#modifyPromoForm", "") + "#modifyPromoForm"
        window.scrollTo({top:0, behavior:"smooth"})
    }

    const modifyPromoCode = async ()=>{
        const modifyReq = await(
            await fetch("/admin/modify-promo-code", {
                method:"POST",
                headers:{
                    "Content-Type":"application/json"
                },
                body:JSON.stringify({
                    code: currentPromo.code,
                    percent: currentPromo.percent,
                    isValid: currentPromo.isValid,
                    isGlobal: currentPromo.isGlobal
                })
            })
        ).text()
        displayAlertModal(modifyReq)
        setIsModifyFormVisible(false)
        setPromoListReload(promoListReload + 1)
    }


    return(
        <div className="apPromos">
            <h2>Promos</h2>

            <form style={{display: displayForm ? "block":"none"}}>
                <input name="promotioncode" className="promopercentinput" type="number" value={promoPercent} onChange={(e)=>{setPromoPercent(parseInt(e.target.value, 10))}} /> <br/>
                <input name="email" className="promopercentinput" type="email" placeholder="E-mail du bénéficiaire" value={promoTargetEmail} onChange={(e)=>{setPromoTargetEmail(e.target.value)}} /> <br/>
                <div className="row isGlobalInputContainer">
                    <label htmlFor="isGlobalInput">Réutilisable</label>
                    <input id="isGlobalInput" type="checkbox" checked={isGlobal} onChange={(e)=>{setIsGlobal(e.target.checked)}} />
                </div>
            </form>

            <button className="send" onClick={createPromoCode}>{displayForm ? "Valider" : "Créer un code promo"}</button>
            <p>
                Par défaut, seuls les 30 derniers codes de promotion sont affichés. Vous pouvez utiliser la barre de recherche ci-dessous pour retrouver un code en particulier.<br/>
                La recherche peut se faire sur le code de promo, sur le mail du parrain ou le nom/mail du proche en cas de parrainage.
            </p>
            
            <div id="modifyPromoForm"  style={{
                height:isModifyFormVisible ? "auto" : 0,
                visibility: isModifyFormVisible ? "visible" : "hidden",
                overflow:isModifyFormVisible ? "visible" : "hidden",
                margin: isModifyFormVisible ? "40px auto 50px auto" : 0
            }} >
                <h2>Modifier une promotion</h2>
                <form className="modifyForm">
                    <input name="promotioncode" type="text" disabled value={currentPromo.code}/>
                    <input name="promotioncode" type="number" value={currentPromo?.percent} onChange={(e)=>{setCurrentPromo({...currentPromo, percent:parseInt(e.target.value, 10)})}} />
                    <div className="row isGlobalInputContainer">
                        <label htmlFor="isGlobalInput">Réutilisable</label>
                        <input id="isGlobalInput" type="checkbox" checked={currentPromo.isGlobal} onChange={(e)=>{setCurrentPromo({...currentPromo, isGlobal:e.target.checked})}} />
                    </div>
                    <div className="row isGlobalInputContainer">
                        <label htmlFor="isGlobalInput">Valide</label>
                        <input id="isGlobalInput" type="checkbox" checked={currentPromo.isValid} onChange={(e)=>{setCurrentPromo({...currentPromo, isValid:e.target.checked})}} />
                    </div>
                    <button className="send modifyBtn" onClick={(e)=>{e.preventDefault(); modifyPromoCode()}}>Valider</button>
                </form>
            </div>

            <input type="text" name="search" placeholder="Code / Nom ou e-mail du proche / E-mail du parrain" value={promoSearch} onChange={(e)=>{setPromoSearch(e.target.value)}} />


            {
                filteredPromos.length ? filteredPromos.map((code, index)=>{
                    return(
                        <div className="apPromoItem" key={index}>
                            {
                                [
                                    code.code, 
                                    code.percent, 
                                    code.isValid ? "Oui" : "Non",
                                    code.isGlobal ? "Oui" : "Non",
                                    code.sponsorship?.fromEmail, 
                                    code.sponsorship?.toName, 
                                    code.sponsorship?.toEmail
                                ].map((info, index)=>{
                                    if(info !== undefined) return(
                                        <div className="row" key={index}>
                                            <h4>{promoInfosLabel[index]}</h4>
                                            <h4>{info }</h4>
                                        </div>
                                    )
                                    else return ""
                                })
                            }

                            <div className="row apPromoItemButtonsContainer">
                                <button className="send" onClick={()=>{goToModifyForm(code)}}>Modifier</button>
                                <button className="send" onClick={()=>{deletePromoCode(code.code)}}>Supprimer</button>
                            </div>
                        </div>
                    )
                })  : ""
            }

        </div>
    )
}