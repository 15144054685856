import React from "react";
import { Animal } from "../utils/generalTypes";
import "./banner.scss"

export default function Banner({setAnimal}:{setAnimal:React.Dispatch<React.SetStateAction<Animal>>}){

    
    return(
        <div id="Banner">
            <div className="bannerContainer">

                <h1>Des croquettes de qualité pour chiens et chats, sans se ruiner.</h1>
                
                <img className="bannerImage" src={require("../images/banner/banner-croquettes.png")} alt=""/>

                <div id="frenchFlag">
                    <div className="blue"></div>
                    <div className="white"></div>
                    <div className="red"></div>
                </div>

                <div id="AnimalSelector">
                    <a className="animal" href="#FoodSelector" onClick={(e)=>{ setAnimal(Animal.Dog)}}>
                        <img src={require("../images/quizz/dog-choice.png")} alt=""/>
                        <p>Chien</p>
                    </a>

                    <a className="animal" href="#FoodSelector" onClick={(e)=>{ setAnimal(Animal.Cat)}} >
                        <img src={require("../images/quizz/cat-choice.png")} alt=""/>
                        <p>Chat</p>
                    </a>
                </div>

                <div className="arrowContainer">
                    <div className="arrowLeft"></div>
                    <div className="arrowRight"></div>
                </div>

            </div>
        </div>
    )
}