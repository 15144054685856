import { Age, Animal, LineType, Product, ProductType } from "./generalTypes"

export const ingredientsDescriptions = {
    agneau:"L’agneau est une source importante de protéines pour votre animal de compagnie. Elle aide à maintenir une structure musculaire saine. Il aide également à maintenir une structure saine de la peau et du pelage tout en reforçant le système immunitaire.",

    riz:"Grâce aux vitamines (E, A, B-6, C) et aux minéraux (calcium, fer, folate, potassium) qu’il contient, il soutient le système digestif, fournit de l’énergie et favorise la santé de la peau. Sa structure facilement digestible et sa légéreté en font une source de glucides idéale pour les animaux souffrant de sensibilité gastrique ou d’allergies.",

    lin:"Les graines de lin contribuent à un soulagement de l’appareil digestif. La présence de mucilage facilite le transit chez les chiens et chez les chats et leur fournit un beau pelage. Il participe également à la satiété et leur confèrent des propriétés diurétiques.",
    
    levure:"La levure de bière contient des vitamines du groupe B qui sont connues pour améliorer le pelage et les griffes des animaux. Elle permet également de pallier à la perte de poils. C’est un complément alimentaire d’origine naturelle et est très bénéfique pour leur santé.",

    saumon : "Le saumon contient les meilleurs types d’acides gras. Il contient également de l’acide gras oméga-3, très bénéfique pour les chats et les chiens, car il contribue à soutenir le processus de croissance des poils et à les rendre plus frais et plus sains. Renforcer la résistance de l’organisme et le système immunitaire",

    citrouille :"La citrouille est riche en vitamine A, bêta-carotène potassium et fer, facilite la digestion avec sa structure fibreuse, et est importante dans le régime alimentaire de nos amis en surpoids",

    epluchures : "Une fois pelées, les pommes de terre se transforment en une excellente ressource alimentaire. Il contient des vitamines E,A,B-9 de la vitamine C, du calcium , du fer et du folate de potassium",

    pois: "Il fournit de nombreux acides aminés essentiels dont les chats et les chiens ont besoin. Des études ont prouvé l’utilité des régimes à base de pois pour les chats et les chiens. Taux de glucides parfaits, les fibres, les protéines sont de nombreuses sources à base de vitamines et minéraux.",

    carotte : "Les carottes contiennent un pourcentage élevé de fibres alimentaires, de biotine, de potassium, de vitamines K, B1 ,B6,B2,C et d’acide lactique. Elle contient également des composant de phosphore, de cuivre, de manganèse, de niacine et d’éléments pantothéniques. Ainsi les carottes permettent a nos animaux de rester en bonne santé",

    grenade : "Une ressources alimentaires riches en vitamines Bon antioxydants il réduit également la formation d’infections et renforce l’estomac. Les enzymes contenues dans les grenades contribuent à la croissance du cartilage",

    myrtilles : "Riche en vitamine A et C, il soutient la santé des cellules et du système urinaire grâce à l’antioxydant",

    huileolive : "L’huile d’olive aide à nettoyer le foie il contribue à réguler le processus digestif et renforce et protèges les os.",

    poulet : "Le poulet est une viande riche en vitamines du groupe B. Il est riche en B3, B6 mais aussi en vitamines B1, B2, B5 et B12. Le poulet est considéré comme une viande maigre, bon pour la ligne et la santé du cerveau.",

    cassava: "Le cassava est un glucide nutritif, très digeste et exempt de grains qui aide à maintenir l'intégrité et la texture des croquettes."
}

export const ingredientsTitles = {
    agneau:"Agneau",
    riz:"Riz",
    lin:"Graines de lin",
    levure:"Levure de bière",
    saumon:"Saumon",
    citrouille : "Citrouille", 
    epluchures:"Épluchures",
    pois: "Petit pois",
    carotte : "Carotte",
    grenade:"Grenade",
    myrtilles:"Myrtilles",
    huileolive : "Huile d'olive",
    poulet:"Poulet",
    cassava:"Cassava",
}


export const productDetailTabTitles = {
    "description":"DESCRIPTION",
    "composition":"COMPOSITION",
    "transition": "PASSER À ",
    "dermasupport": "DERMA SUPPORT",
    "everythingabout":"TOUT SAVOIR SUR "
}

export const dermaSupportText = "Nourrir votre animal ne consiste pas seulement à le nourrir. Il s’agit par la même occasion de lui faire découvrir des aliments qui contribueront à son bon développement et contribueront à éliminer la sensation de faim. Consciente de cette situation, notre entreprise sélectionne soigneusement les ingrédients des aliments dans le souci d’une alimentation saine. Les proportions des ingrédients sont déterminées en fonction des besoins et des sensibilités de votre ami animal. Nos produits ne contiennent ni soja ni maïs et sont composés de nutriments à haute valeur nutritionnelle et digestibilité.\n\n\nSoutien dermatologique\n\nLes problèmes que nos amis les animaux rencontrent avec leur santé cutanée peuvent être dus à des allergies alimentaires ou à des affections cutanées allergiques, en plus de facteurs externes. Cette situation affecte négativement leur qualité de vie. Afin de soutenir les sensibilités cutanées, il est extrêmement important que l'aliment contienne de la vitamine E, du ratio oméga3, du ratio oméga6, du zinc et des vitamines A, B et D, ainsi que la qualité de la teneur en matières premières de l'aliment. Le contenu des produits Myfood Derma Support a été formulé en tenant compte de tous ces critères.Nos produits de soutien sont formulés pour prévenir l'apparition d'inconforts, en tenant compte des sensibilités de nos amis les animaux. Consultez votre vétérinaire pour le traitement des troubles qui surviennent. La nutrition est extrêmement importante pour que vos amis animaux puissent vivre une vie saine et de qualité. Lors du choix des sources de protéines et des céréales, les nutriments les plus bénéfiques ont été sélectionnés en tenant compte du système digestif de nos amis les animaux de compagnie."


const randomCommonProduct:Product = {
    age:Age.All,
    animal:Animal.All,
    brandDescription:"",
    description:"",
    title:"",
    id:"",
    imgSrc:"",
    brandName:"",
    stockAvailable:false,
    stockQuantity:0,
    productType:ProductType.Food,
    lineType:LineType.Premium,
}

export const randomFoodProduct:Product = {
    ...randomCommonProduct,
    dailyQuantities:[],
    stringIngredients:"",
    nutritionalValues:"",
    hasDermaSupport:false,
    availableLines:[],
    ingredients:[]
}

export const randomOtherProduct:Product = {
    ...randomCommonProduct,
    price:0,
    weight:0
}