import React, { useState } from "react";
import { DailyQuantity, Product, ProductDetailTabType, ProductType } from "../utils/generalTypes";
import { dermaSupportText, productDetailTabTitles } from "../utils/generalData";
import "./productDetailTab.scss"

export default function ProductDetailTab({tab, product}:{tab:ProductDetailTabType, product:Product}){

    const [isVisible, setIsVisible] = useState<boolean>(false)

    let tabTitle = productDetailTabTitles[tab]
    if(tab === "transition" || tab === "everythingabout"){
        tabTitle += product.brandName.toUpperCase()
    }

    let contentToDisplay:React.JSX.Element
    switch(tab){
        case "description":
            contentToDisplay = <Description description={product.description} productType={product.productType} />
            break;
        case "composition":
            contentToDisplay = <Composition stringIngredients={product.stringIngredients ?? ""} nutritionalValues={product.nutritionalValues ?? ""} dailyQuantities={product.dailyQuantities ?? []}/>
            break;
        case "transition":
            contentToDisplay = <Transition brandName={product.brandName}/>
            break;
        case "dermasupport":
            contentToDisplay = <DermaSupport/>
            break;
        case "everythingabout":
            contentToDisplay = <EverythingAbout brandDescription={product.brandDescription}/>
            break;
        }

    


    return(
        <div className="ProductDetailTab">
            <div className="tabTitle" onClick={()=>{setIsVisible(!isVisible)}}>
                <p>{tabTitle}</p>
                <img className={"openIcon"+(isVisible ? " visible" : "")} src={require("../images/productDetail/up-arrow.png")} alt=""/>
            </div>
            <div className={"content"+(isVisible ? " visible" : "")}>
                {contentToDisplay}
            </div>
        </div>
    )
}

const Description = ({description, productType}:{description:string, productType:ProductType})=>{
    return(
        <div className="description">
            <p>{description}</p>
            <p style={{display:productType === ProductType.Treat ? "block":"none"}}>
            <br/><br/>Rue canine propose une gamme de friandises 100% naturelles est sélectionnée pour répondre à leur besoin. <br/>
Nos produits ont de nombreux bienfaits (apaisant, relaxant, calmant, renforcement du système immunitaire…) <br/><br/>

- 100% naturel, sans additif ni conservateur<br/>
- longue durée de conservation <br/>
- riche en protéine calcium et fer <br/>
- hygiene bucco-dentaire <br/>
- apaisant, relaxant <br/><br/>

La mastication va contribuer à votre chien de gérer ses émotions de le détendre de l’occuper de longs moments pour éviter aussi certains problèmes comportementaux. 
<br/><br/>
La santé et le bien être de votre chien notre priorité et nous en sommes fier de lui offrir des produits de hautes qualité et 100% naturelle
<br/><br/>
Pour toutes demandes supplémentaires nous restons à votre disposition 
<br/><br/>
Pour être plus proche de vous n’hésitez pas à nous suivre sur les réseaux nous répondrons à vos messages<br/>
            </p>
            {productType === ProductType.Accessory ? <img className="guideCollier" src={require("../images/productDetail/guide-collier.jpeg")} alt="" /> : ""}
        </div>
    )
}

const Composition = ({stringIngredients, nutritionalValues, dailyQuantities}:{stringIngredients:string, nutritionalValues:string, dailyQuantities:DailyQuantity[]}) =>{
    return(
        <div className="composition">
            <h2>Ingrédients</h2>
            <p>{stringIngredients}</p>
            <h2>Valeurs nutritionelles</h2>
            <p>{nutritionalValues}</p>
            <h2>Quantité journalière</h2>
            <table>
                <tbody>
                    {
                        dailyQuantities.map((d, key)=>{
                            return(
                                <tr key={key}>
                                    <td>{d.weight} kg</td>
                                    <td>{d.range.join(" gr - ")} gr</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}

const Transition = ({brandName}:{brandName:string})=>{

    let steps:number[][] = [[25,75], [50,50], [75, 25], [100]]

    return(
        <div className="transition">
            <h3>Pour transitionner de vos croquettes actuelles à {brandName}, rien de plus simple, suivez les quatres étapes suivantes :</h3>
            <div className="stepListContainer">
                {
                    steps.map((step, index)=>{
                        return( 
                            <div className="stepContainer" key={index}>
                                <div className={"circle step"+(index+1)}/>
                                <div>
                                    {
                                        index !== 3 ? <p>{`Mettez ${step[0]}% de vos croquettes actuelles et `}<span className="bold">{`${step[1]}% de croquettes ${brandName}.`}</span>  </p>
                                        
                                        : <p>Vous pouvez désormais mettre <span className="bold">{`100% de nos croquettes ${brandName} !`}</span></p>
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

const DermaSupport = ()=>{
    return(
        <div className="dermasupport">
            <p>{dermaSupportText}</p>
        </div>
    )
}

const EverythingAbout = ({brandDescription}:{brandDescription:string})=>{
    return(
        <div className="everythingabout">
            <p>{brandDescription}</p>
        </div>
    )
}